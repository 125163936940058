import React, { useEffect, useState } from "react";
import {
    Button,
    Drawer,
    Form,
    Input,
    message,
    Modal,
    Select,
    Table,
    Popconfirm,
    Upload
} from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    UploadOutlined,
    PlusOutlined
} from "@ant-design/icons";
import { Excel } from "antd-table-saveas-excel";
import { SiMicrosoftexcel } from "react-icons/si";
import { useSelector } from "react-redux";
import {
    addNews,
    deleteNews,
    getNews,
    showNews,
    updateNews
} from "../../../api/dialog/newsAPI";
import { getLanguage } from "../../../api/language";
import { getContent } from "../../../api/dialog/contentAPI";
import styles from "./style.module.css";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

export default function News() {
    const [edit, setEdit] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [news, setNews] = useState([]);
    const [selectedNews, setSelectedNews] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [contents, setContents] = useState([]);
    const [form] = Form.useForm();
    const theme = useSelector((state) => state.theme.theme);

    useEffect(() => {
        fetchNews();
        fetchLanguages();
        fetchContents();
    }, []);

    const fetchNews = async () => {
        setLoading(true);
        try {
            const data = await getNews();
            setNews(data);
        } catch (error) {
            console.error('Error fetching news:', error);
        }
        setLoading(false);
    };

    const fetchLanguages = async () => {
        try {
            const data = await getLanguage();
            setLanguages(data);
        } catch (error) {
            console.error('Error fetching languages:', error);
        }
    };

    const fetchContents = async () => {
        try {
            const data = await getContent();
            setContents(data);
        } catch (error) {
            console.error('Error fetching contents:', error);
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
        form.resetFields();
    };

    const handleOk = () => {
        form.submit();
    };

    const handleCancel = () => {
        setEdit(false);
        setIsModalOpen(false);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onFinish = async (values) => {
        const formData = new FormData();
        for (const key in values) {
            if (key === 'images' && values[key]) {
                values[key].forEach((file, index) => {
                    formData.append(`${key}[${index}]`, file.originFileObj);
                });
            } else if (key === 'video' && values[key]) {
                formData.append(key, values[key].originFileObj);
            } else if (key === 'audio' && values[key]) {
                formData.append(key, values[key].originFileObj);
            } else if (key === 'cover' && values[key]) {
                formData.append(key, values[key].originFileObj);
            } else if (values[key]) {
                formData.append(key, values[key]);
            }
        }

        try {
            if (edit) {
                await updateNews(selectedNews.id, formData);
                message.success("Новость успешно обновлена");
            } else {
                await addNews(formData);
                message.success("Новость успешно добавлена");
            }
            await fetchNews();
            setIsModalOpen(false);
        } catch (error) {
            console.log('Error updating/adding news', error);
            message.error('Ошибка при сохранении новости');
        }
    };

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Заголовок',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Действия',
            render: (record) => {
                return (
                    <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                        <div onClick={async () => {
                            const response = await showNews(record.id);
                            setSelectedNews(response);
                            setOpen(true);
                        }} className={'action_buttons bg-indigo-500 text-white'}>
                            <EyeOutlined />
                        </div>
                        <div onClick={() => {
                            setEdit(true);
                            setSelectedNews(record);
                            form.setFieldsValue(record);
                            setIsModalOpen(true);
                        }} className={'action_buttons bg-amber-500 text-white'}>
                            <EditOutlined />
                        </div>

                        <Popconfirm
                            title="Удалить новость"
                            description="Вы уверены, что хотите удалить эту новость?"
                            onConfirm={async () => {
                                await deleteNews(record.id);
                                await fetchNews();
                            }}
                            okText="Да"
                            cancelText="Нет"
                            okButtonProps={{ className: 'bg-red-600' }}
                        >
                            <div className={'action_buttons bg-red-600 text-white'}>
                                <DeleteOutlined />
                            </div>
                        </Popconfirm>
                    </div>
                );
            }
        }
    ];

    const handleExport = () => {
        const excel = new Excel();
        excel
            .addSheet("News")
            .addColumns(columns)
            .addDataSource(news, {
                str2Percent: true
            })
            .saveAs("News.xlsx");
    };

    return (
        <div className={'w-full'}>
                <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        Новости
                    </div>

                    <div className={styles.tools}>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <Button onClick={showModal} type="primary" className={'bg-green-600'}>
                                Добавить новость +
                            </Button>

                            <Button type={"primary"} onClick={handleExport}
                                    className={'bg-blue-400 flex flex-row text-xl items-center'}>
                                <SiMicrosoftexcel />
                            </Button>
                        </div>

                        <div className={'flex flex-row items-center'}>
                            <div className={`font-sans font-semibold ${theme === 'dark' ? 'text-white' : 'text-black/60'} mr-4`}>Поиск</div>
                            <Input
                                placeholder="Введите текст для поиска"
                                allowClear
                                onChange={(e) => {
                                    const searchValue = e.target.value.toLowerCase();
                                    setNews(news.filter(item =>
                                        item.title.toLowerCase().includes(searchValue)
                                    ));
                                }}
                                style={{ width: 200 }}
                            />
                        </div>
                    </div>
                </div>

                <Modal title="Заполните форму новости" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <Form
                        form={form}
                        name="newsForm"
                        initialValues={edit ? selectedNews : {}}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="title"
                            label="Заголовок"
                            rules={[{ required: true, message: 'Пожалуйста, введите заголовок!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label="Описание"
                        >
                            <TextArea rows={4} />
                        </Form.Item>

                        <Form.Item
                            name="cover"
                            label="Обложка"
                            valuePropName="file"
                            getValueFromEvent={e => e && e.fileList[0]}
                        >
                            <Upload listType="picture" beforeUpload={() => false}>
                                <Button icon={<UploadOutlined />}>Загрузить обложку</Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name="images"
                            label="Изображения"
                            valuePropName="fileList"
                            getValueFromEvent={e => e && e.fileList}
                        >
                            <Upload listType="picture" multiple beforeUpload={() => false}>
                                <Button icon={<UploadOutlined />}>Загрузить изображения</Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name="video"
                            label="Видео"
                            valuePropName="file"
                            getValueFromEvent={e => e && e.fileList[0]}
                        >
                            <Upload accept="video/mp4,video/avi,video/mov" beforeUpload={() => false}>
                                <Button icon={<UploadOutlined />}>Загрузить видео</Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name="audio"
                            label="Аудио"
                            valuePropName="file"
                            getValueFromEvent={e => e && e.fileList[0]}
                        >
                            <Upload accept="audio/mp3,audio/wav" beforeUpload={() => false}>
                                <Button icon={<UploadOutlined />}>Загрузить аудио</Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name="language_id"
                            label="Язык"
                            rules={[{ required: true, message: 'Пожалуйста, выберите язык!' }]}
                        >
                            <Select placeholder="Выберите язык">
                                {languages.map((language) => (
                                    <Option key={language.id} value={language.id}>
                                        {language.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="content_id"
                            label="Контент"
                            rules={[{ required: true, message: 'Пожалуйста, выберите контент!' }]}
                        >
                            <Select placeholder="Выберите контент">
                                {contents.map((content) => (
                                    <Option key={content.id} value={content.id}>
                                        {content.title}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>

                <div className={`w-full ${theme === 'dark' ? 'custom_dark' : `custom_light`}`}>
                    <Table className={'shadow-2xl w-full rounded-xl'} dataSource={news} columns={columns} />
                </div>

                <Drawer title="Новость" onClose={onClose} open={open}>
                    <div className={'flex w-full flex-col items-center text-start gap-y-2'}>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <p className={'text-xl'}>Заголовок: </p>
                            <p className={'text-xl font-semibold'}>{selectedNews?.title}</p>
                        </div>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <p className={'text-xl'}>Описание: </p>
                            <p className={'text-xl font-semibold'}>{selectedNews?.description}</p>
                        </div>
                    </div>
                </Drawer>
            </div>
        </div>
    );
}
