import axios from 'axios';

const API_URL = process.env.REACT_APP_URL;

const sessionsApi = {
    getUsers: () =>
        axios.get(`${API_URL}api/clients`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }),
    getPsychologistSessions: () =>
        axios.get(`${API_URL}api/session/psychologist`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }),
    getSessions: () =>
        axios.get(`${API_URL}api/sessions`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }),
    createSession: (data) =>
        axios.post(`${API_URL}api/sessions`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }),
};

export default sessionsApi;
