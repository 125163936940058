import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const ballAPI = axios.create({
    baseURL,
});

// Получить все баллы для теста
export const DialogGetBalls = async (testId) => {
    const token = localStorage.getItem('token');
    try {
        const response = await ballAPI.get(`${baseURL}api/contents/${testId}/balls`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

// Получить конкретный балл по ID для теста
export const DialogShowBall = async (testId, ballId) => {
    const token = localStorage.getItem('token');
    try {
        const response = await ballAPI.get(`${baseURL}api/contents/${testId}/balls/${ballId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

// Добавить новый балл к тесту
export const DialogAddBall = async (testId, data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await ballAPI.post(`${baseURL}api/contents/${testId}/balls`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

// Обновить балл для теста
export const DialogUpdateBall = async (testId, ballId, data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await ballAPI.put(`${baseURL}api/contents/${testId}/balls/${ballId}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

// Удалить балл для теста
export const DialogDeleteBall = async (testId, ballId) => {
    const token = localStorage.getItem('token');
    try {
        const response = await ballAPI.delete(`${baseURL}api/contents/${testId}/balls/${ballId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};
