import React, { useEffect, useState } from "react";
import {
    Button,
    Drawer,
    Form,
    Input,
    message,
    Modal,
    Select,
    Table,
    Popconfirm,
    Upload
} from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined, UploadOutlined, PlusOutlined } from "@ant-design/icons";
import { Excel } from "antd-table-saveas-excel";
import { SiMicrosoftexcel } from "react-icons/si";
import { useSelector } from "react-redux";
import {
    getTest,
    showTest,
    addTest,
    updateTest,
    deleteTest
} from "../../../api/dialog/testAPI";
import {
    DialogGetAnswers,
    DialogShowAnswer,
    DialogAddAnswer,
    DialogUpdateAnswer,
    DialogDeleteAnswer
} from "../../../api/dialog/answerAPI";
import { getContent } from "../../../api/dialog/contentAPI"; // Добавляем API для получения контента
import styles from "./style.module.css";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

export default function Tests() {
    const [edit, setEdit] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [tests, setTests] = useState([]);
    const [contents, setContents] = useState([]);
    const [selectedTest, setSelectedTest] = useState({});
    const [answers, setAnswers] = useState([]);
    const [selectedAnswer, setSelectedAnswer] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAnswerModalOpen, setIsAnswerModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [answerForm] = Form.useForm();
    const theme = useSelector((state) => state.theme.theme);

    useEffect(() => {
        fetchTests();
        fetchContents(); // Получаем список контентов
    }, []);

    const fetchTests = async () => {
        setLoading(true);
        try {
            const data = await getTest();
            setTests(data);
        } catch (error) {
            console.error('Error fetching tests:', error);
        }
        setLoading(false);
    };

    const fetchContents = async () => {
        try {
            const data = await getContent();
            setContents(data);
        } catch (error) {
            console.error('Error fetching contents:', error);
        }
    };

    const fetchAnswers = async (testId) => {
        setLoading(true);
        try {
            const data = await DialogGetAnswers(testId);
            setAnswers(data);
        } catch (error) {
            console.error('Error fetching answers:', error);
        }
        setLoading(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
        form.resetFields();
    };

    const showAnswerModal = () => {
        setIsAnswerModalOpen(true);
        answerForm.resetFields();
    };

    const handleOk = () => {
        form.submit();
    };

    const handleAnswerOk = () => {
        answerForm.submit();
    };

    const handleCancel = () => {
        setEdit(false);
        setIsModalOpen(false);
    };

    const handleAnswerCancel = () => {
        setEdit(false);
        setIsAnswerModalOpen(false);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onFinish = async (values) => {
        const formData = new FormData();
        for (const key in values) {
            if (key === 'images' && values[key]) {
                values[key].forEach((file, index) => {
                    formData.append(`${key}[${index}]`, file.originFileObj);
                });
            } else if (key === 'video' && values[key]) {
                formData.append(key, values[key].originFileObj);
            } else if (key === 'audio' && values[key]) {
                formData.append(key, values[key].originFileObj);
            } else if (values[key]) {
                formData.append(key, values[key]);
            }
        }

        try {
            if (edit) {
                await updateTest(selectedTest.id, formData);
                message.success("Тест успешно обновлен");
            } else {
                await addTest(formData);
                message.success("Тест успешно добавлен");
            }
            await fetchTests();
            setIsModalOpen(false);
        } catch (error) {
            console.log('Error updating/adding test', error);
            message.error('Ошибка при сохранении теста');
        }
    };

    const onAnswerFinish = async (values) => {
        try {
            if (edit) {
                await DialogUpdateAnswer(selectedTest.id, selectedAnswer.id, values);
                message.success("Ответ успешно обновлен");
            } else {
                await DialogAddAnswer(selectedTest.id, values);
                message.success("Ответ успешно добавлен");
            }
            await fetchAnswers(selectedTest.id);
            setIsAnswerModalOpen(false);
        } catch (error) {
            console.log('Error updating/adding answer', error);
            message.error('Ошибка при сохранении ответа');
        }
    };

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Вопрос',
            dataIndex: 'question',
            key: 'question',
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Действия',
            render: (record) => {
                return (
                    <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                        <div onClick={async () => {
                            const response = await showTest(record.id);
                            setSelectedTest(response);
                            await fetchAnswers(record.id);
                            setOpen(true);
                        }} className={'action_buttons bg-indigo-500 text-white'}>
                            <EyeOutlined />
                        </div>
                        <div onClick={() => {
                            setEdit(true);
                            setSelectedTest(record);
                            form.setFieldsValue(record);
                            setIsModalOpen(true);
                        }} className={'action_buttons bg-amber-500 text-white'}>
                            <EditOutlined />
                        </div>

                        <Popconfirm
                            title="Удалить тест"
                            description="Вы уверены, что хотите удалить этот тест?"
                            onConfirm={async () => {
                                await deleteTest(record.id);
                                await fetchTests();
                            }}
                            okText="Да"
                            cancelText="Нет"
                            okButtonProps={{ className: 'bg-red-600' }}
                        >
                            <div className={'action_buttons bg-red-600 text-white'}>
                                <DeleteOutlined />
                            </div>
                        </Popconfirm>
                    </div>
                );
            }
        }
    ];

    const answerColumns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Текст ответа',
            dataIndex: 'answer_text',
            key: 'answer_text',
        },
        {
            title: 'Баллы',
            dataIndex: 'points',
            key: 'points',
        },
        {
            title: 'Действия',
            render: (record) => {
                return (
                    <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                        <div onClick={async () => {
                            const response = await DialogShowAnswer(selectedTest.id, record.id);
                            setSelectedAnswer(response);
                            setEdit(true);
                            answerForm.setFieldsValue(response);
                            setIsAnswerModalOpen(true);
                        }} className={'action_buttons bg-amber-500 text-white'}>
                            <EditOutlined />
                        </div>

                        <Popconfirm
                            title="Удалить ответ"
                            description="Вы уверены, что хотите удалить этот ответ?"
                            onConfirm={async () => {
                                await DialogDeleteAnswer(selectedTest.id, record.id);
                                await fetchAnswers(selectedTest.id);
                            }}
                            okText="Да"
                            cancelText="Нет"
                            okButtonProps={{ className: 'bg-red-600' }}
                        >
                            <div className={'action_buttons bg-red-600 text-white'}>
                                <DeleteOutlined />
                            </div>
                        </Popconfirm>
                    </div>
                );
            }
        }
    ];

    const handleExport = () => {
        const excel = new Excel();
        excel
            .addSheet("Tests")
            .addColumns(columns)
            .addDataSource(tests, {
                str2Percent: true
            })
            .saveAs("Tests.xlsx");
    };

    return (
        <div className={'w-full'}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        Тесты
                    </div>

                    <div className={styles.tools}>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <Button onClick={showModal} type="primary" className={'bg-green-600'}>
                                Добавить тест +
                            </Button>

                            <Button type={"primary"} onClick={handleExport}
                                    className={'bg-blue-400 flex flex-row text-xl items-center'}>
                                <SiMicrosoftexcel />
                            </Button>
                        </div>

                        <div className={'flex flex-row items-center'}>
                            <div className={`font-sans font-semibold ${theme === 'dark' ? 'text-white' : 'text-black/60'} mr-4`}>Поиск</div>
                            <Input
                                placeholder="Введите текст для поиска"
                                allowClear
                                onChange={(e) => {
                                    const searchValue = e.target.value.toLowerCase();
                                    setTests(tests.filter(test =>
                                        test.question.toLowerCase().includes(searchValue)
                                    ));
                                }}
                                style={{ width: 200 }}
                            />
                        </div>
                    </div>
                </div>

                <Modal title="Заполните форму теста" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <Form
                        form={form}
                        name="testForm"
                        initialValues={edit ? selectedTest : {}}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="question"
                            label="Вопрос"
                            rules={[{required: true, message: 'Пожалуйста, введите вопрос!'}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label="Описание"
                        >
                            <TextArea rows={4}/>
                        </Form.Item>

                        <Form.Item
                            name="images"
                            label="Изображения"
                            valuePropName="fileList"
                            getValueFromEvent={e => Array.isArray(e) ? e : e && e.fileList}
                        >
                            <Upload listType="picture" multiple>
                                <Button icon={<UploadOutlined />}>Загрузить изображения</Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name="video"
                            label="Видео"
                            valuePropName="file"
                            getValueFromEvent={e => Array.isArray(e) ? e : e && e.fileList[0]}
                        >
                            <Upload accept="video/mp4,video/avi,video/mov" beforeUpload={() => false}>
                                <Button icon={<UploadOutlined />}>Загрузить видео</Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name="audio"
                            label="Аудио"
                            valuePropName="file"
                            getValueFromEvent={e => Array.isArray(e) ? e : e && e.fileList[0]}
                        >
                            <Upload accept="audio/mp3,audio/wav" beforeUpload={() => false}>
                                <Button icon={<UploadOutlined />}>Загрузить аудио</Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name="content_id"
                            label="Контент"
                            rules={[{required: true, message: 'Пожалуйста, выберите контент!'}]}
                        >
                            <Select placeholder="Выберите контент">
                                {contents.map((content) => (
                                    <Option key={content.id} value={content.id}>
                                        {content.title}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal title="Заполните форму ответа" open={isAnswerModalOpen} onOk={handleAnswerOk} onCancel={handleAnswerCancel}>
                    <Form
                        form={answerForm}
                        name="answerForm"
                        initialValues={edit ? selectedAnswer : {}}
                        onFinish={onAnswerFinish}
                    >
                        <Form.Item
                            name="answer_text"
                            label="Текст ответа"
                            rules={[{required: true, message: 'Пожалуйста, введите текст ответа!'}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name="points"
                            label="Баллы"
                            rules={[{required: true, message: 'Пожалуйста, введите количество баллов!'}]}
                        >
                            <Input type="number"/>
                        </Form.Item>
                    </Form>
                </Modal>

                <div className={`w-full ${theme === 'dark' ? 'custom_dark' : `custom_light`}`}>
                    <Table className={'shadow-2xl w-full rounded-xl'} dataSource={tests} columns={columns}/>
                </div>

                <Drawer title="Тест" onClose={onClose} open={open}>
                    <div className={'flex w-full flex-col items-center text-start gap-y-2'}>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <p className={'text-xl'}>Вопрос: </p>
                            <p className={'text-xl font-semibold'}>{selectedTest?.question}</p>
                        </div>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <p className={'text-xl'}>Описание: </p>
                            <p className={'text-xl font-semibold'}>{selectedTest?.description}</p>
                        </div>
                        <div className={'flex flex-row items-center justify-between w-full'}>
                            <p className={'text-xl font-semibold'}>Ответы:</p>
                            <Button onClick={showAnswerModal} type="primary" className={'bg-green-600'}>
                                <PlusOutlined /> Добавить ответ
                            </Button>
                        </div>
                        <Table className={'w-full mt-2'} dataSource={answers} columns={answerColumns}/>
                    </div>
                </Drawer>
            </div>
        </div>
    );
}
