import React, { useEffect, useState } from "react";
import {
    Button,
    Drawer,
    Form,
    Input,
    message,
    Modal,
    Select,
    Table,
    Popconfirm,
} from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Excel } from "antd-table-saveas-excel";
import { SiMicrosoftexcel } from "react-icons/si";
import { useSelector } from "react-redux";
import {
    getContent,
    showContent,
    addContent,
    updateContent,
    deleteContent
} from "../../../api/dialog/contentAPI";
import { DialogGetCategories } from "../../../api/dialog/categoryAPI";
import { getLanguage } from "../../../api/language";
import styles from "./style.module.css";
import TextArea from "antd/es/input/TextArea";
import { getImages } from "../../../api/imageManager";
import Search from "antd/es/input/Search";
import BallManager from "./BallManager"; // Импорт компонента BallManager

const { Option } = Select;

export default function ContentManager() {
    const [edit, setEdit] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [contents, setContents] = useState([]);
    const [filteredContents, setFilteredContents] = useState([]);
    const [categories, setCategories] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedContent, setSelectedContent] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const theme = useSelector((state) => state.theme.theme);
    const [images, setImages] = useState([]);
    const [coverPath, setSelectedCoverPath] = useState('');
    const [filteredImages, setFilteredImages] = useState([]);

    useEffect(() => {
        fetchLanguages();
        fetchContents();
        fetchImages();
    }, []);

    useEffect(() => {
        if (selectedLanguage) {
            fetchCategories(selectedLanguage);
        }
    }, [selectedLanguage]);

    useEffect(() => {
        filterContentsByLanguage(selectedLanguage);
    }, [selectedLanguage, contents]);

    const api_url = process.env.REACT_APP_URL;

    const fetchImages = async () => {
        try {
            const data = await getImages();
            setFilteredImages(data);
            setImages(data);
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    const fetchContents = async () => {
        setLoading(true);
        try {
            const data = await getContent();
            setContents(data);
            setFilteredContents(data);
        } catch (error) {
            console.error('Error fetching contents:', error);
        }
        setLoading(false);
    };

    const fetchCategories = async (languageCode) => {
        try {
            const data = await DialogGetCategories(languageCode);
            setCategories(data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchLanguages = async () => {
        try {
            const data = await getLanguage();
            setLanguages(data);
            setSelectedLanguage(data[0]?.code || '');
        } catch (error) {
            console.error('Error fetching languages:', error);
        }
    };

    const handleSearch = (searchValue) => {
        const filtered = images.filter((value) => {
            return value.name.toLowerCase().includes(searchValue.toLowerCase());
        });
        setFilteredImages(filtered);
    };

    const showModal = () => {
        setIsModalOpen(true);
        form.resetFields();
    };

    const handleOk = () => {
        form.submit();
    };

    const handleCancel = () => {
        setEdit(false);
        setIsModalOpen(false);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onFinish = async (values) => {
        try {
            values.cover = coverPath;
            if (edit) {
                await updateContent(selectedContent.category_id, selectedContent.id, values);
                message.success("Контент успешно обновлен");
            } else {
                const categoryId = values.category_id;
                await addContent(categoryId, values);
                message.success("Контент успешно добавлен");
            }
            await fetchContents();
            setIsModalOpen(false);
        } catch (error) {
            console.log('Error updating/adding content', error);
            message.error('Ошибка при сохранении контента');
        }
    };

    const filterContentsByLanguage = (languageCode) => {
        if (languageCode) {
            const filtered = contents.filter(content => content.category.language.code === languageCode);
            setFilteredContents(filtered);
        } else {
            setFilteredContents(contents);
        }
    };

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Название',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Категория',
            dataIndex: 'category_id',
            key: 'category_id',
            render: (category_id) => {
                const category = categories.find(cat => cat.id === category_id);
                return category ? category.name : 'Нет';
            }
        },
        {
            title: 'Тип контента',
            dataIndex: 'type',
            key: 'type',
            render: (type) => {
                switch (type) {
                    case 'page': return 'Простой контент';
                    case 'news': return 'Новости';
                    case 'test': return 'Тест';
                    default: return 'Неизвестно';
                }
            }
        },
        {
            title: 'Язык',
            dataIndex: ['category', 'language', 'code'],
            key: 'language',
            render: (code, record) => {
                return record.category.language.name;
            }
        },
        {
            title: 'Действия',
            render: (record) => {
                return (
                    <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                        <div onClick={async () => {
                            const response = await showContent(record.category_id, record.id);
                            setSelectedContent(response);
                            setOpen(true);
                        }} className={'action_buttons bg-indigo-500 text-white'}>
                            <EyeOutlined />
                        </div>
                        <div onClick={() => {
                            setEdit(true);
                            setSelectedContent(record);
                            form.setFieldsValue(record);
                            setIsModalOpen(true);
                        }} className={'action_buttons bg-amber-500 text-white'}>
                            <EditOutlined />
                        </div>

                        <Popconfirm
                            title="Удалить контент"
                            description="Вы уверены, что хотите удалить этот контент?"
                            onConfirm={async () => {
                                await deleteContent(record.category_id, record.id);
                                await fetchContents();
                            }}
                            okText="Да"
                            cancelText="Нет"
                            okButtonProps={{ className: 'bg-red-600' }}
                        >
                            <div className={'action_buttons bg-red-600 text-white'}>
                                <DeleteOutlined />
                            </div>
                        </Popconfirm>
                    </div>
                );
            }
        }
    ];

    const handleExport = () => {
        const excel = new Excel();
        excel
            .addSheet("Contents")
            .addColumns(columns)
            .addDataSource(filteredContents, {
                str2Percent: true
            })
            .saveAs("Contents.xlsx");
    };

    return (
        <div className={'w-full'}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        Контент
                    </div>

                    <div className={styles.tools}>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <Button onClick={showModal} type="primary" className={'bg-green-600'}>
                                Добавить контент +
                            </Button>

                            <Button type={"primary"} onClick={handleExport}
                                    className={'bg-blue-400 flex flex-row text-xl items-center'}>
                                <SiMicrosoftexcel />
                            </Button>
                        </div>

                        <div className={'flex flex-row items-center'}>
                            <Select
                                value={selectedLanguage}
                                onChange={(value) => setSelectedLanguage(value)}
                                className={'mr-4'}
                                style={{ width: 200 }}
                            >
                                <Option value="">Все языки</Option>
                                {languages.map((language) => (
                                    <Option key={language.code} value={language.code}>
                                        {language.name} ({language.code})
                                    </Option>
                                ))}
                            </Select>
                            <div className={`font-sans font-semibold ${theme === 'dark' ? 'text-white' : 'text-black/60'} mr-4`}>Поиск</div>
                            <Input
                                placeholder="Введите текст для поиска"
                                allowClear
                                onChange={(e) => {
                                    const searchValue = e.target.value.toLowerCase();
                                    setFilteredContents(contents.filter(content =>
                                        content.title.toLowerCase().includes(searchValue)
                                    ));
                                }}
                                style={{ width: 200 }}
                            />
                        </div>
                    </div>
                </div>

                <Modal title="Заполните форму контента" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <Form
                        form={form}
                        name="contentForm"
                        initialValues={edit ? selectedContent : {}}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="title"
                            label="Заголовок"
                            rules={[{ required: true, message: 'Пожалуйста, введите заголовок!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label="Описание"
                        >
                            <TextArea rows={4} />
                        </Form.Item>

                        <Form.Item
                            name="language"
                            label="Язык"
                            rules={[{ required: true, message: 'Пожалуйста, выберите язык!' }]}
                        >
                            <Select
                                value={selectedLanguage}
                                onChange={(value) => setSelectedLanguage(value)}
                                placeholder="Выберите язык"
                            >
                                {languages.map((language) => (
                                    <Option key={language.code} value={language.code}>
                                        {language.name} ({language.code})
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="category_id"
                            label="Категория"
                            rules={[{ required: true, message: 'Пожалуйста, выберите категорию!' }]}
                        >
                            <Select placeholder="Выберите категорию">
                                {categories.map((category) => (
                                    <Option key={category.id} value={category.id}>
                                        {category.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="type"
                            label="Тип контента"
                            rules={[{ required: true, message: 'Пожалуйста, выберите тип контента!' }]}
                        >
                            <Select placeholder="Выберите тип контента">
                                <Option value="page">Простой контент</Option>
                                <Option value="news">Новости</Option>
                                <Option value="test">Тест</Option>
                            </Select>
                        </Form.Item>

                        <div className={'flex flex-row flex-wrap'}>
                            <Search
                                placeholder={'Kategoriya va nomi bo`yicha izlash '}
                                style={{ width: '100%' }}
                                onSearch={handleSearch}
                            />
                        </div>

                        <div className={'flex flex-row w-full flex-wrap mt-1 rounded-lg border border-blue-700 p-2 h-[400px] overflow-scroll'}>
                            {filteredImages.map(value => (
                                <div className={'flex flex-col'} key={value.id}>
                                    <img
                                        onClick={() => setSelectedCoverPath(value.path)}
                                        src={`${api_url}${value.path}`}
                                        alt={value.name}
                                        className={`w-20 h-20 flex cursor-pointer m-1 ${coverPath === value.path ? 'border-2 p-1 rounded-lg shadow-2xl border-blue-800' : ''}`}
                                    />
                                    <p className={`text-[12px] font-bold ${coverPath === value.path ? 'text-white duration-200 bg-blue-800 rounded-lg' : ''} text-blue-700 text-center`}>{value.name}</p>
                                </div>
                            ))}
                        </div>
                    </Form>
                </Modal>

                <div className={`w-full ${theme === 'dark' ? 'custom_dark' : `custom_light`}`}>
                    <Table className={'shadow-2xl w-full rounded-xl'} dataSource={filteredContents} columns={columns} />
                </div>

                <Drawer title="Контент" onClose={onClose} open={open}>
                    <div className={'flex w-full flex-col items-center text-start gap-y-2'}>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <p className={'text-xl'}>Название: </p>
                            <p className={'text-xl font-semibold'}>{selectedContent?.title}</p>
                        </div>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <p className={'text-xl'}>Тип контента: </p>
                            <p className={'text-xl font-semibold'}>{selectedContent?.type}</p>
                        </div>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <p className={'text-xl'}>Категория: </p>
                            <p className={'text-xl font-semibold'}>{categories.find(category => category.id === selectedContent?.category_id)?.name || 'Нет'}</p>
                        </div>
                    </div>
                    {selectedContent.type === 'test' && (
                        <BallManager contentId={selectedContent.id} />
                    )}
                </Drawer>
            </div>
        </div>
    );
}
