// slider.js
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const sliderAPI = axios.create({
    baseURL,
});

export const getSlider = async (languageCode) => {
    const token = localStorage.getItem('token');
    try {
        const response = await sliderAPI.get(`${baseURL}api/slider?language=${languageCode}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const addSlide = async (data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await sliderAPI.post(`${baseURL}api/slider`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const deleteSlide = async (id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await sliderAPI.delete(`${baseURL}api/slider/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const updateSlide = async (id, data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await sliderAPI.put(`${baseURL}api/slider/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};
