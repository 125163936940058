import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const answerAPI = axios.create({
    baseURL,
});

// Получить все ответы для теста
export const DialogGetAnswers = async (testId) => {
    const token = localStorage.getItem('token');
    try {
        const response = await answerAPI.get(`${baseURL}api/tests/${testId}/answers`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

// Получить конкретный ответ по ID для теста
export const DialogShowAnswer = async (testId, answerId) => {
    const token = localStorage.getItem('token');
    try {
        const response = await answerAPI.get(`${baseURL}api/tests/${testId}/answers/${answerId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

// Добавить новый ответ к тесту
export const DialogAddAnswer = async (testId, data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await answerAPI.post(`${baseURL}api/tests/${testId}/answers`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

// Обновить ответ для теста
export const DialogUpdateAnswer = async (testId, answerId, data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await answerAPI.put(`${baseURL}api/tests/${testId}/answers/${answerId}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

// Удалить ответ для теста
export const DialogDeleteAnswer = async (testId, answerId) => {
    const token = localStorage.getItem('token');
    try {
        const response = await answerAPI.delete(`${baseURL}api/tests/${testId}/answers/${answerId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};
