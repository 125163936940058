import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const psychologistsAPI = axios.create({
    baseURL,
});

export const getPsychologists = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await psychologistsAPI.get(`${baseURL}api/psychologists`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log(response.data);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const showPsychologist = async (id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await psychologistsAPI.get(`${baseURL}api/psychologists/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const addPsychologist = async (data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await psychologistsAPI.post(`${baseURL}api/psychologists`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const deletePsychologist = async (psychologistID) => {
    const token = localStorage.getItem('token');
    try {
        const response = await psychologistsAPI.delete(`${baseURL}api/psychologists/${psychologistID}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const updatePsychologist = async (psychologistID, data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await psychologistsAPI.put(`${baseURL}api/psychologists/${psychologistID}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};
