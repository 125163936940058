// bottles
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const bottlesAPI = axios.create({
    baseURL,
});

export const getOrders = async (lang) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.get(`${baseURL}api/orders`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const getTotalLastweekOrder = async (lang) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.get(`${baseURL}api/orders-total-week`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};
export const getTotalPendingLastweekOrder = async (lang) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.get(`${baseURL}api/orders-total-pending`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const getTotalHoldLastweekOrder = async (lang) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.get(`${baseURL}api/orders-total-hold`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const showOrders = async (id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.get(`${baseURL}api/products/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const addOrders = async (data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.post(`${baseURL}api/makeOrder`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const makeOrder = async (data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.post(`${baseURL}api/makeOrder`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const pendingOrder = async (order_id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.patch(`${baseURL}api/orders-pending`, {order_id}, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const completedOrder = async (order_id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.patch(`${baseURL}api/orders-completed`, {order_id}, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const holdOrder = async (order_id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.patch(`${baseURL}api/orders-hold`, {order_id}, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};



export const deleteOrders = async (LanguageID) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.delete(`${baseURL}api/orders/${LanguageID}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};


export const changeOrderCourier = async (orderId,courierId, data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.put(`${baseURL}api/orders/${orderId}/change-courier/${courierId}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};



