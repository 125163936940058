import React, {useEffect, useRef, useState} from "react";
import "./style.css";
import {Button, Drawer, Dropdown, Form, Image, Input, message, Modal, Popconfirm, Table, Tour, Upload} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons";
import {addAdmins, deleteAdmin, getAdmins, showAdmin, updateAdmin} from "../../../api/service_admins";
import {LiaUserShieldSolid} from "react-icons/lia";
import {Excel} from "antd-table-saveas-excel";
import {SiMicrosoftexcel} from "react-icons/si";
import {useSelector} from "react-redux";
import {addImages, deleteImages, getImages, showImages, updateImages} from "../../../api/imageManager";
import {MdOutlineTour} from "react-icons/md";
import tour from '../../../assets/images/tour.png'
export default function ImageManager() {
    const [openTour, setOpenTour] = useState(false);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);

    const [edit, setEdit] = useState(false)

    const [isLoading, setLoading] = useState(false)

    const [admins, setAdmins] = useState([])
    const [adminsOld, setAdminsOld] = useState([])
    const [selectedAdmin, setSelectedAdmin] = useState([]);

    const [photoFile, setPhotoFile] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [open, setOpen] = useState(false);
    const [image, setImage] = useState(null);
    const [form] = Form.useForm();
    const [modalVisibleTable, setModalVisibleTable] = useState(false);
    const [selectedImageTable, setSelectedImageTable] = useState('');

    const handleImageClick = (imageUrl) => {
        setSelectedImageTable(imageUrl);
        setModalVisibleTable(true);
    };

    const tourSteps = [
        {
            title: 'Rasmlar yuklash',
            description: 'Buyerda saytda bo`lishi mumkin bo`lgan barcha rasmlar qo`shiladi, buyerdan barcha muqova va ikonva va shunga o`xshash barcha elementlarga qo`shiladi',
            cover: (
                <img
                    alt="tour.png"
                    src={tour}
                />
            ),
            target: () => ref1.current,
        },
        {
            title: 'Excel import',
            description: 'Tablitsani siz excel faylga import qilib yuklab olishingi mumkin',
            target: () => ref2.current,
        },

    ]

    const theme = useSelector((state) => state.theme.theme);


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit()
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const handleImage = (info) => {
        setImage(info.file)
    };


    useEffect(() => {
        fetchImages()
    }, [])

    const fetchImages = async () => {
        setLoading(true);
        try {
            const data = await getImages();
            setAdmins(data);
            setAdminsOld(data)
        } catch (error) {
            console.error('Error fetching admins:', error);
        }
        setLoading(false);
    };

    const onFinish = async (values) => {
        console.log("submit")
        try {
            if (edit){
                values.image = values.image.file
                await updateImages(selectedAdmin.id,values);
                setAdmins(admins)
                message.success("изоображения успешно добавьлен в сервер")
                await fetchImages()
            } else {
                values.image = values.image.file
                console.log(values.path)
                await addImages(values);
                setAdmins(admins)
                message.success("изоображения успешно добавьлен в сервер")
                await fetchImages()
            }
        } catch (error) {
            console.log('Error adding images ', error)
        }
    }

    const deleteButtonProps = {
        style: {
            backgroundColor: 'red', // Set the default background color
        },
        // Add other props as needed
    };

    const confirmButtonProps = {
        style: {
            backgroundColor: '#0c75ff', // Set the default background color
        },
        // Add other props as needed
    };

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name',
        },

        {
            title: 'path',
            render: (record) => {
                return <img onClick={()=>handleImageClick(`${api_url}${record}`)} src={`${api_url}${record}`} className={'w-20 h-20 object-cover cursor-pointer'}/>
            },
            dataIndex: 'path',
            key: 'path',
        },
        {
            title: 'Actions',
            render: (record) => {
                return <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                    <div onClick={ async()=>{
                        const  response = await showImages(record.id);
                        setSelectedAdmin(response)
                        setOpen(true)
                    }} className={'action_buttons bg-indigo-500 text-white'}>
                        <EyeOutlined />
                    </div>
                    <div onClick={()=>{
                        setSelectedAdmin(record)
                    }} className={'action_buttons bg-amber-500 text-white'}>
                        <EditOutlined/>
                    </div>

                    <Popconfirm
                        title="Delete the admin"
                        description="Are you sure to delete this admin?"
                        onConfirm={async () => {

                            await deleteImages(record.id)
                            await fetchImages()
                        }
                        }
                        okButtonProps={deleteButtonProps}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div className={'action_buttons bg-red-600 text-white'}>
                            <DeleteOutlined/>
                        </div>
                    </Popconfirm>


                </div>
            }
        }
    ];

    const columns2 = [
        {
            title: 'Nomi',
            dataIndex: 'name',
            key: 'fullname',
        },
        {
            title: 'catrgoriyasi',
            dataIndex: 'img_category',
            key: 'img_category',
        },

    ];

    const handleClick = () => {
        const excel = new Excel();
        excel
            .addSheet("test")
            .addColumns(columns2)
            .addDataSource(admins, {
                str2Percent: true
            })
            .saveAs("Excel.xlsx");
    };

    useEffect(()=>{},[admins])

    const onSearch =  (values) => {

        if (values.target.value.length === 0){
            setAdmins(adminsOld)
        } else {
            setAdmins(filterAdmins(values.target.value.toLowerCase()))
        }


    }

    // axios.put(baseUrl, data)
    function filterAdmins(value) {
        return admins.filter(admin => {

            return admin.name.toLowerCase().includes(value);

        });
    }
    const api_url = process.env.REACT_APP_URL;
    return <div className={'w-full'}>
        <div className={` w-[100%] p-0`}>
            <div className={`header_admin`}>
                <div className={'flex flex-row'}>
                <div className={`title`}>
                    Rasmlar
                    <LiaUserShieldSolid className={'text-[28px]'}/>
                </div>
                    <div className={`bg-green-600 cursor-pointer text-white rounded flex flex-row self-center text-[14px] items-center p-1`} onClick={()=>setOpenTour(true)}>
                        Rasmlar bo`yicha tur
                        <MdOutlineTour className={'text-[28px]'}/>
                    </div>
            </div>

                <div className={`tools`}>
                    <div className={'flex flex-row items-center gap-x-2'}>
                        <Button
                            ref={ref1}
                            onClick={showModal}
                            type="primary"
                            className={'bg-green-600'}
                        >
                            Rasm qo`shish +
                        </Button>

                        <Button ref={ref2} type={"primary"} onClick={()=>{handleClick()}}
                                className={'bg-blue-400 flex flex-row text-xl items-center'}><SiMicrosoftexcel />
                        </Button>
                    </div>

                    <div className={'flex flex-row items-center'} ref={ref3}>
                        <div className={`font-sans font-semibold ${theme ==='dark'? 'text-white' : 'text-black/60'}  mr-4`}>Izlash</div>
                        <Input
                            placeholder="Izlash uchun so`ni kiriting nomi va categoriyasi bo`yicha"
                            allowClear
                            onChange={onSearch}
                            style={{
                                width: 200,
                            }}
                        />
                    </div>

                </div>

            </div>

            <Modal title="Fill the admin form" open={isModalOpen } okButtonProps={confirmButtonProps} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    name="addAdmins"
                    initialValues={{remember: true}}
                    onFinish={onFinish}

                >

                    <Form.Item
                        label="Nomi"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your fullname!',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>


                    <Form.Item label="Изображение" name="image">
                        <Upload
                            accept="image/*"
                            maxCount={1}

                            beforeUpload={() => false}
                            listType="picture"
                            showUploadList={{
                                showRemoveIcon: false,
                            }}
                            onChange={handleImage}
                        >
                            <Button icon={<UploadOutlined/>}>Yuklash</Button>
                        </Upload>
                    </Form.Item>


                </Form>
            </Modal>
            <div className={`w-full ${theme === 'dark'? 'custom_dark' : `custom_light`} `}>
                <Table className={'shadow-2xl w-full rounded-xl'} dataSource={admins} columns={columns}/>
            </div>

            <Drawer title="Basic Drawer" onClose={onClose} open={open}>

                <div className={'flex w-full flex-col items-center text-start gap-y-2'}>

                    <div className={'w-9/12 h-fit'}>
                        <img src={`${api_url}${selectedAdmin?.path}`}
                             className={'w-full h-full object-cover'}/>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2'}>
                        <p className={'text-xl'}>Nomlanishi : </p>

                        <p className={'text-xl font-semibold'}>{selectedAdmin?.name}</p>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2 '}>
                        <p className={'text-xl'}>Categoriyasi : </p>

                        <p className={'text-xl font-semibold'}>{selectedAdmin?.img_category}</p>
                    </div>
                </div>


            </Drawer>
        </div>
        <Modal
            visible={modalVisibleTable}
            onCancel={() => setModalVisibleTable(false)}
            footer={null}
        >
            {/* Отображение выбранного изображения в модальном окне */}
            <img src={selectedImageTable} alt="Selected Image" style={{ width: '100%' }} />
        </Modal>
        <Tour type={'primary'} open={openTour} onClose={() => setOpenTour(false)} steps={tourSteps} indicatorsRender={(current, total) => (
            <span>
            {current + 1} / {total}
          </span>
        )} />
    </div>
}
