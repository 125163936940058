import React, {useEffect, useRef, useState} from 'react';
import {Avatar, Typography, Input, Button, notification, message} from "antd";
import { getClients } from "../../../../api/dialog/ClientsApi";
import {UserOutlined, VideoCameraAddOutlined} from "@ant-design/icons";
import {getMessages, getMessages as apiGetMessages} from "../../../../api/messaging/messageApi";
import { io } from "socket.io-client";
import {MdOutlinePhone} from "react-icons/md";
import {CgProfile} from "react-icons/cg";
import {TbSettings2} from "react-icons/tb";
import {IoMdClose} from "react-icons/io";
import bg_chat from  '../../../../assets/images/bg_chat.jpg';
import {GrAchievement} from "react-icons/gr";
import {BiSolidVideo} from "react-icons/bi";
import VideoChat from "./videoChat/VideoChat";
const { TextArea, Search } = Input;

export default function Chat(props) {
    const [messages, setMessages] = useState([]);
    const [content, setContent] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [socket, setSocket] = useState(null);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const token = localStorage.getItem('token');
    const my_id = localStorage.getItem('my_id');
    const profile =  localStorage.getItem('user');
    const [isVideoChat, setIsVideoChat] = useState(false);
    const [showProfile ,setShowProfile] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const chat_container = useRef(null);
    const  [calling , setCalling ] = useState(false);
    const [receivingCall, setReceivingCall] = useState(false);
    const [caller, setCaller] = useState("");
    const [callerSignal, setCallerSignal] = useState(null);
    const openNotification = (title,description) => {
        api.open({
            message: title,
            description: description,
            duration: 1,
        });
    };

    useEffect(() => {

        const socketInstance = io('https://service.lideryam.com', {
            path: '/socket.io',  // Укажи правильный путь для Socket.io
            query: { token },
            transports: ['websocket'],  // Явно укажи, что используешь WebSocket транспорт
        });


        setSocket(socketInstance);

        // Подключение к серверу
        socketInstance.on('connect', () => {
            console.log('Connected to server');
        });
        socketInstance.on("callIncoming", (data) => {
            setReceivingCall(true);
            setCaller(data.from);
            setCallerSignal(data.signal);
            setIsVideoChat(true);
            message.info('calling');
        });
        // Подписка на события сообщений от сервера
        socketInstance.on('message', (message) => {
            console.log('Received message:', message);
            if (message.sender_id === selectedUser?.id || message.recipient_id === selectedUser?.id) {
                setMessages((prevMessages) => [...prevMessages, message]);
            }
            console.log(getClientByID(5));
            if (getClientByID(message.sender_id)[0]?.first_name !== undefined){
                openNotification(getClientByID(message.sender_id)[0]?.first_name, message.content);
            }
        });



        // Очистка при размонтировании компонента
        return () => {
            socketInstance.disconnect();
        };
    }, [token, selectedUser]);

    const getClientByID = (id)=>{
        return users.filter(user => user.id === id);
    }


    useEffect(() => {
        // Получение списка психологов
        const fetchUsers = async () => {
            try {
                const response = await getClients(token);
                setUsers(response);
                setFilteredUsers(response);
                console.log(response);
            } catch (error) {
                console.error('Failed to fetch psychologists:', error);
            }
        };

        fetchUsers();
    }, [token]);

    useEffect(() => {
        if (selectedUser) {
            // Получение сообщений при выборе психолога
            const fetchMessages = async () => {
                try {
                    const response = await apiGetMessages(token);
                    console.log(response.data);
                    setMessages(response.data.filter(
                        msg => msg.sender_id === selectedUser.id || msg.recipient_id === selectedUser.id
                    ).reverse());

                } catch (error) {
                    console.error('Failed to fetch messages:', error);
                }
            };

            fetchMessages();
        }
    }, [selectedUser, token]);
    useEffect(() => {

        chat_container.current?.scrollIntoView()
    }, [messages,isVideoChat]);

    useEffect(() => {
        // Фильтрация пользователей по поисковому запросу
        setFilteredUsers(users.filter(user =>
            user.first_name.toLowerCase().includes(searchTerm.toLowerCase())
        ));
    }, [searchTerm, users]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (socket) {
                socket.emit('sendMessage', { recipient_id: selectedUser.id, content });
                console.log('Message sent:', { recipient_id: selectedUser.id, content });
            }
            setContent('');
        } catch (error) {
            console.error('Failed to send message:', error);
        }
    };

    const handleUserSelect = (user) => {
        setSelectedUser(user);
        setMessages([]);
    };

    return (
        <div className="w-full h-[87vh] overscroll-none  flex flex-col p-2 md:p-2 duration-300">

            {contextHolder}
            <Typography.Title level={4}>Список сообщений</Typography.Title>
            <div className="w-full h-full flex flex-col md:flex-row ">

                <div
                    className="w-full md:w-[400px] bg-white border-gray-300 border rounded-xl p-2 md:p-4 flex flex-col overflow-auto">
                    <div className={'w-full h-[70px] flex flex-row bg-blue-500 items-center p-1 rounded-lg mb-2 cursor-pointer'}>
                        <Avatar className={'w-[60px] h-[50px] items-center flex-row flex justify-center'}
                                icon={<UserOutlined/>}/>
                        <div className={'w-full flex flex-col'}>
                        <Typography  className={'ml-4 font-bold text-white'}> {profile}</Typography>
                        <Typography  className={'ml-4 font-thin text-white'}>Психолог</Typography>
                        </div>
                    </div>
                    <Search
                        placeholder="Поиск по имени"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{marginBottom: '10px'}}
                    />
                    {filteredUsers.map((user) => (
                        <div
                            key={user.id}
                            onClick={() => handleUserSelect(user)}
                            className="cursor-pointer mb-2"
                        >
                            {userCard(user.first_name, user.phone_number)}
                        </div>
                    ))}
                </div>

                <div className="flex-1  flex flex-col bg-white border border-gray-100 rounded-xl ml-2 pb-4">
                    <div className={'w-full  h-[80px] rounded-t-xl border-b border-gray-300 flex flex-row justify-between'}>
                        <div className={'flex flex-row w-[200px] h-full items-center'}>
                            <Avatar className={'w-[50px] h-[50px] items-center flex-row flex justify-center ml-4'} icon={<UserOutlined />} />
                            <div className={'flex flex-col ml-4'}>
                               <Typography> {getClientByID(selectedUser?.id)[0]?.first_name}</Typography>
                               <Typography> {getClientByID(selectedUser?.id)[0]?.phone_number}</Typography>
                            </div>
                        </div>
                        <div className={'flex flex-row w-[400px] h-full text-gray-300  items-center justify-center border-l'}>
                            <MdOutlinePhone className={'text-[32px] text-gray-300 cursor-pointer hover:text-gray-800 rounded-full border hover:border-gray-800 duration-300  border-gray-300 p-1'} />
                            <BiSolidVideo onClick={()=>{
                                if (selectedUser !== null) {
                                    setCalling(true)
                                }  else {
                                    setCalling(false)
                                }
                                setIsVideoChat(!isVideoChat)

                            }} className={'text-[32px] text-gray-300 cursor-pointer hover:text-gray-800 rounded-full border hover:border-gray-800 duration-300  border-gray-300 p-1 ml-4'} />
                            <CgProfile onClick={()=>{setShowProfile(!showProfile)}}  className={'text-[32px] text-gray-300 cursor-pointer hover:text-gray-800 rounded-full hover:border-gray-800 duration-300 border border-gray-300 p-1 ml-4'} />
                            <TbSettings2   className={'text-[32px] text-gray-300  cursor-pointer hover:text-gray-800 rounded-full hover:border-gray-800 duration-300 border border-gray-300 p-1 ml-4'} />
                            <GrAchievement    className={'text-[32px] text-gray-300  cursor-pointer hover:text-gray-800 rounded-full hover:border-gray-800 duration-300 border border-gray-300 p-1 ml-4'} />
                        </div>
                    </div>
                    {isVideoChat ? <VideoChat socket={socket} recipientId={selectedUser?.id} calling={calling} caller={caller} callerSignal={callerSignal} receivingCall={receivingCall}/> : <div style={{background: `url(${bg_chat})`}}
                                                       className="flex-1 rounded-lg p-1  overflow-y-auto mb-4 ml-4 mr-4 mt-2">
                        {messages.map((message, index) => (
                            <div key={index}
                                 className={`mb-2 ${message.sender_id === selectedUser?.id ? 'text-left' : 'text-right'}`}>
                                <div
                                    className={`inline-block p-2 rounded ${message.sender_id === selectedUser?.id ? 'bg-green-500 text-white' : 'bg-blue-800 text-white'} cursor-pointer`}>
                                    {message.content}
                                </div>
                            </div>
                        ))}
                        <div ref={chat_container}>
                            -
                        </div>

                    </div>
                    }

                    <form onSubmit={handleSubmit} className="flex flex-col md:flex-row ">
                        <TextArea
                            rows={2}
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            placeholder="Напишите сообщение..."
                            className="flex-1 mb-2 md:mb-0 md:mr-2"
                        />
                        <Button type="primary" htmlType="submit"
                                className="w-full md:w-auto bg-blue-700">Отправить</Button>
                    </form>
                </div>
                {showProfile && (
                    <div className={'w-[400px] flex flex-col h-full border border-gray-300 bg-white ml-4 rounded-xl'}>
                        <div className={'flex h-[60px] border-b border-gray-300 flex-row items-center justify-between gap-x-2'}>
                            <Typography className={'ml-4 text-[18px]'}> Информация о пользователе</Typography>
                            <IoMdClose  className={'text-gray-800 text-[32px] mt-2 mr-2 cursor-pointer'}/>
                        </div>
                        <div className={'flex flex-col h-[270px] w-full items-center border-b border-gray-300'}>
                            <Avatar className={'w-[100px] h-[100px] text-[18px] items-center flex-row flex justify-center mt-4'} icon={<UserOutlined />} />
                         <Typography className={'font-semibold text-[28px]'}>    {getClientByID(selectedUser?.id)[0]?.first_name}  {getClientByID(selectedUser?.id)[0]?.last_name }  </Typography>
                         <Typography className={'font-semibold text-[22px]'}>  Yoshi :   {getClientByID(selectedUser?.id)[0]?.age} </Typography>
                        </div>
                        <div className={'w-[90%] h-[50px] rounded-lg items-center flex flex-row  bg-green-500 mt-4 ml-4 cursor-pointer '}>
                            <MdOutlinePhone className={'text-[32px] ml-6 text-gray-white cursor-pointer  rounded-full border  duration-300  border-gray-300 p-1'} />
                            <Typography className={' text-[16px] text-white ml-2'}> + {getClientByID(selectedUser?.id)[0]?.phone_number} </Typography>
                        </div>
                    <div className={'w-full h-fir overflow-y-scroll border-t border-gray-300 mt-4 items-center pl-2'}>
                      <Typography>  {getClientByID(selectedUser?.id)[0]?.about}</Typography>
                    </div>
                </div>)}
            </div>
        </div>
    );
}

const userCard = (name, phone) => {
    return (
        <div className="w-full h-[70px] bg-gray-50 rounded-lg flex flex-row p-1 items-center">
            <Avatar className={'w-[50px] h-[40px] items-center flex-row flex justify-center'} icon={<UserOutlined />} />
            <div className="w-full flex flex-col ml-4">
                <Typography className="text-blue-600 border-b border-gray-200 w-full">{name}</Typography>
                <Typography className="text-gray-800">{phone}</Typography>
            </div>
        </div>
    );
};
