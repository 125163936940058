import React, { useEffect, useState } from "react";
import {
    Button,
    Drawer,
    Form,
    Input,
    message,
    Modal,
    Popconfirm,
    Select,
    Table,
    Checkbox
} from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Excel } from "antd-table-saveas-excel";
import { SiMicrosoftexcel } from "react-icons/si";
import { useSelector } from "react-redux";
import {
    DialogGetCategories,
    DialogShowCategory,
    DialogAddCategory,
    DialogDeleteCategory,
    DialogUpdateCategory
} from "../../../api/dialog/categoryAPI";
import { getLanguage } from "../../../api/language";
import styles from "./style.module.css";

const { Option } = Select;

export default function Categories() {
    const [edit, setEdit] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState();
    const [selectedCategory, setSelectedCategory] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const theme = useSelector((state) => state.theme.theme);

    useEffect(() => {
        fetchLanguages();
    }, []);

    useEffect(() => {
        if (selectedLanguage) {
            fetchCategories();
        }
    }, [selectedLanguage]);

    const fetchCategories = async () => {
        setLoading(true);
        try {
            const data = await DialogGetCategories(selectedLanguage || 'uz');
            setCategories(data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
        setLoading(false);
    };

    const fetchLanguages = async () => {
        try {
            const data = await getLanguage();
            setSelectedLanguage(data[0]?.code || 'uz');
            setLanguages(data);
        } catch (error) {
            console.error('Error fetching languages:', error);
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
        form.resetFields();
    };

    const handleOk = () => {
        form.submit();
    };

    const handleCancel = () => {
        setEdit(false);
        setIsModalOpen(false);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onFinish = async (values) => {
        if (values.parent_id === undefined) {
            values.parent_id = null;
        }

        // Ensure in_app is set to false if not checked
        if (values.in_app === undefined) {
            values.in_app = false;
        }

        try {
            if (edit) {
                await DialogUpdateCategory(selectedCategory.id, values);
                message.success("Категория успешно обновлена");
            } else {
                await DialogAddCategory(values);
                message.success("Категория успешно добавлена");
            }
            await fetchCategories();
            setIsModalOpen(false);
        } catch (error) {
            console.log('Error updating/adding category', error);
            message.error('Ошибка при сохранении категории');
        }
    };

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Родительская категория',
            dataIndex: 'parent_id',
            key: 'parent_id',
            render: (parent_id) => {
                const parentCategory = categories.find(category => category.id === parent_id);
                return parentCategory ? parentCategory.name : 'Нет';
            }
        },
        {
            title: 'Язык',
            dataIndex: 'language_id',
            key: 'language_id',
            render: (language_id) => {
                const language = languages.find(language => language.id === language_id);
                return language ? `${language.name} (${language.code})` : 'Не задано';
            }
        },
        {
            title: 'В приложении',
            dataIndex: 'in_app',
            key: 'in_app',
            render: (in_app) => (in_app ? 'Да' : 'Нет')
        },
        {
            title: 'Действия',
            render: (record) => {
                return (
                    <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                        <div onClick={async () => {
                            const response = await DialogShowCategory(record.id);
                            setSelectedCategory(response);
                            setOpen(true);
                        }} className={'action_buttons bg-indigo-500 text-white'}>
                            <EyeOutlined />
                        </div>
                        <div onClick={() => {
                            setEdit(true);
                            setSelectedCategory(record);
                            form.setFieldsValue({
                                ...record,
                                parent_id: record.parent_id === null ? undefined : record.parent_id,
                                in_app: !!record.in_app
                            });
                            setIsModalOpen(true);
                        }} className={'action_buttons bg-amber-500 text-white'}>
                            <EditOutlined />
                        </div>

                        <Popconfirm
                            title="Удалить категорию"
                            description="Вы уверены, что хотите удалить эту категорию?"
                            onConfirm={async () => {
                                await DialogDeleteCategory(record.id);
                                await fetchCategories();
                            }}
                            okText="Да"
                            cancelText="Нет"
                            okButtonProps={{ className: 'bg-red-600' }}
                        >
                            <div className={'action_buttons bg-red-600 text-white'}>
                                <DeleteOutlined />
                            </div>
                        </Popconfirm>
                    </div>
                );
            }
        }
    ];

    const handleExport = () => {
        const excel = new Excel();
        excel
            .addSheet("Categories")
            .addColumns(columns)
            .addDataSource(categories, {
                str2Percent: true
            })
            .saveAs("Categories.xlsx");
    };

    return (
        <div className={'w-full'}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        Категории
                    </div>

                    <div className={styles.tools}>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <Button onClick={showModal} type="primary" className={'bg-green-600'}>
                                Добавить категорию +
                            </Button>

                            <Button type={"primary"} onClick={handleExport}
                                    className={'bg-blue-400 flex flex-row text-xl items-center'}>
                                <SiMicrosoftexcel />
                            </Button>
                        </div>

                        <div className={'flex flex-row items-center'}>
                            <Select
                                value={selectedLanguage}
                                onChange={(value) => setSelectedLanguage(value)}
                                className={'mr-4'}
                                style={{ width: 200 }}
                            >
                                {languages.map((language) => (
                                    <Option key={language.code} value={language.code}>
                                        {language.name} ({language.code})
                                    </Option>
                                ))}
                            </Select>
                            <div className={`font-sans font-semibold ${theme === 'dark' ? 'text-white' : 'text-black/60'} mr-4`}>Поиск</div>
                            <Input
                                placeholder="Введите текст для поиска"
                                allowClear
                                onChange={(e) => {
                                    const searchValue = e.target.value.toLowerCase();
                                    setCategories(categories.filter(category =>
                                        category.name.toLowerCase().includes(searchValue) ||
                                        (category.parent_id && category.parent_id.toString().includes(searchValue)) ||
                                        (category.language_id && category.language_id.toString().includes(searchValue))
                                    ));
                                }}
                                style={{ width: 200 }}
                            />
                        </div>
                    </div>
                </div>

                <Modal title="Заполните форму категории" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <Form
                        form={form}
                        name="categoryForm"
                        initialValues={edit ? selectedCategory : {}}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Название"
                            name="name"
                            rules={[{ required: true, message: 'Пожалуйста, введите название категории!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Родительская категория"
                            name="parent_id"
                            rules={[{ required: false, message: 'Выберите родительскую категорию (если есть)' }]}
                        >
                            <Select placeholder="Выберите родительскую категорию" allowClear>
                                <Option value={undefined}>Нет</Option>
                                {categories.map((category) => (
                                    <Option key={category.id} value={category.id}>
                                        {category.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Язык"
                            name="language_id"
                            rules={[{ required: true, message: 'Пожалуйста, выберите язык!' }]}
                        >
                            <Select placeholder="Выберите язык">
                                {languages.map((language) => (
                                    <Option key={language.id} value={language.id}>
                                        {language.name} ({language.code})
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="В приложении"
                            name="in_app"
                            valuePropName="checked"
                        >
                            <Checkbox>В приложении</Checkbox>
                        </Form.Item>
                    </Form>
                </Modal>

                <div className={`w-full ${theme === 'dark' ? 'custom_dark' : `custom_light`}`}>
                    <Table className={'shadow-2xl w-full rounded-xl'} dataSource={categories} columns={columns} />
                </div>

                <Drawer title="Категория" onClose={onClose} open={open}>
                    <div className={'flex w-full flex-col items-center text-start gap-y-2'}>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <p className={'text-xl'}>Название: </p>
                            <p className={'text-xl font-semibold'}>{selectedCategory?.name}</p>
                        </div>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <p className={'text-xl'}>Родительская категория: </p>
                            <p className={'text-xl font-semibold'}>{categories.find(category => category.id === selectedCategory?.parent_id)?.name || 'Нет'}</p>
                        </div>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <p className={'text-xl'}>Язык: </p>
                            <p className={'text-xl font-semibold'}>{languages.find(language => language.id === selectedCategory?.language_id)?.name || 'Не задано'}</p>
                        </div>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <p className={'text-xl'}>В приложении: </p>
                            <p className={'text-xl font-semibold'}>{selectedCategory?.in_app ? 'Да' : 'Нет'}</p>
                        </div>
                    </div>
                </Drawer>
            </div>
        </div>
    );
}
