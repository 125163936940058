import React, { useState, useEffect, useMemo } from 'react';
import {Calendar, Modal, Select, Form, Input, TimePicker, Tooltip, Drawer, Button} from 'antd';
import moment from 'moment';
import sessionsApi from "../../../api/dialog/sessions";
import { getPsychologists } from "../../../api/dialog/psychologistsAPI";
import { TiMessage } from "react-icons/ti";
import { HiOutlineClock } from "react-icons/hi";
import { BsFilePerson } from "react-icons/bs";
import Chat from "./Messages/Chat";

const { Option } = Select;

const SessionCalendar = () => {
    const [visible, setVisible] = useState(false);
    const [users, setUsers] = useState([]);
    const [clients, setClients] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        fetchClients();
        fetchPsychologies();
        fetchSessions();
    }, []);

    const fetchClients = async () => {
        try {
            const response = await sessionsApi.getUsers();
            setClients(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchPsychologies = async () => {
        try {
            const response = await getPsychologists();
            setUsers(response);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchSessions = async () => {
        try {
            const response = localStorage.getItem('role') !== 'psychologist' ? await sessionsApi.getSessions() : await sessionsApi.getPsychologistSessions();
            const sec = await sessionsApi.getSessions();
            console.log(sec);
            console.log(response);
            setSessions(response.data);
        } catch (error) {
            console.error('Error fetching sessions:', error);
        }
    };

    const onSelectDate = (date) => {
        setSelectedDate(date);
        setVisible(true);
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            const sessionTime = selectedDate.clone().set({
                hour: values.time.hour(),
                minute: values.time.minute()
            });
            await sessionsApi.createSession({
                psychologist_id: localStorage.getItem('my_id'),
                client_id: values.client_id,
                description: values.description,
                session_time: sessionTime.format('YYYY-MM-DD HH:mm:ss'),
            });
            setVisible(false);
            form.resetFields();
            fetchSessions();
        } catch (error) {
            console.error('Error creating session:', error);
        }
    };

    const handleCancel = () => {
        setVisible(false);
        form.resetFields();
    };

    const [arrow, setArrow] = useState('Show');
    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }

        if (arrow === 'Show') {
            return true;
        }

        return {
            pointAtCenter: true,
        };
    }, [arrow]);

    const dateCellRender = (value) => {
        const daySessions = sessions.filter(
            (session) => moment(session.session_time).format('YYYY-MM-DD') === value.format('YYYY-MM-DD')
        );



        return (
            <div className={'w-full h-full'} >
                <ul>
                    {daySessions.map((session) => (
                        <li key={session.id} className={'bg-green-500 text-white w-fit rounded mt-2'}>
                            <Tooltip placement="top" title={`vaqti :${session.session_time} qo'shimcha ma'lumot :  ${session.description}`} arrow={mergedArrow}>
                                <div className={'flex flex-row items-center'}>
                                    <HiOutlineClock className={'ml-2 mr-2'} />
                                    {moment(session.session_time).format(' HH:mm')}
                                    <BsFilePerson  className={'ml-2 mr-2'} />
                                    {session?.client?.first_name}
                                </div>
                            </Tooltip>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <div>
            <Button type="bg-green" onClick={showDrawer}>
                Open
            </Button>
            <Calendar dateCellRender={dateCellRender} onSelect={onSelectDate} />
            <Drawer  width={'100%'} title="Message Inbox" onClose={onClose} open={open}>
            <Chat/>
            </Drawer>
            <Modal title="Назначить сессию" visible={visible} onOk={handleOk} onCancel={handleCancel}>
                <Form form={form} layout="vertical">
                    {localStorage.getItem('role') !== 'psychologist' && (
                        <Form.Item name="psychologist_id" label="Психолог" rules={[{ required: true, message: 'Выберите психолога' }]}>
                            <Select placeholder="Выберите психолога">
                                {users.map((user) => (
                                    <Option key={user.id} value={user.id}>
                                        {user.first_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                    <Form.Item name="client_id" label="Клиент" rules={[{ required: true, message: 'Выберите клиента' }]}>
                        <Select placeholder="Выберите клиента">
                            {clients.map((user) => (
                                <Option key={user.id} value={user.id}>
                                    {user.first_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="time" label="Время" rules={[{ required: true, message: 'Выберите время' }]}>
                        <TimePicker format="HH:mm" />
                    </Form.Item>
                    <Form.Item name="description" label="Описание">
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default SessionCalendar;
