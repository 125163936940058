import React, {useEffect} from "react";
import styles from "./style.module.css";
import {useDispatch, useSelector} from "react-redux";
import Login from "../Auth/Login/Login";
import Sidebar from "../../components/Sidebar";
import {Route, Routes} from "react-router-dom";
import Dashboard from "../../components/Dashboard";
import Layout, {Content, Header} from "antd/es/layout/layout";

import Drivers from "../Drivers/Drivers";
import Admins from "../ShopPages/Admins";
import Content_Header from "../../components/Content_Header";
import {login, logout} from "../../store/actions/authActions";
import Operators from "../Operators";
import Warehouse from "../Warehouse/Warehouse";
import BottlesTypes from "../BottleTypes/BottlesTypes";
import Bottles from "../Bottles/Bottles";
import WarehouseBottles from "../WarehouseBottles/WarehouseBottles";
import DriverWarehouseBottle from "../DriverWarehouseBottle/DriverWarehouseBottle";
import DriverWarehouses from "../DriverWarehouses/DriverWarehouses";
import Cars from "../Cars/Cars";

import Cards from "../Cards/Cards";
import CashbackHistory from "../CashbackHistory/CashbackHistory";
import History from "../History/History";
import DemoDashboard from "../DemoDashboard/DemoDashboard";
import ImageManager from "../ShopPages/ImageManager/ImageManager";
import Languages from "../ShopPages/Languages/Languages";
import Icons from "../ShopPages/Icons/Icons";
import CategoryType from "../ShopPages/Category_Type/CategoryType";
import Category from "../ShopPages/Category/Category";
import Brands from "../ShopPages/Brands/Brands";
import Products from "../ShopPages/Products/Products";
import Orders from "../ShopPages/Orders/Orders";
import Users from "../ShopPages/Users/Users";

import Couriers from "../ShopPages/Couriers";
import WarehouseProduct from "../ShopPages/Warehouses/WarehouseProduct";
import DriverCards from "../ShopPages/DriverCards";
import News from "../ShopPages/News/News";
import Slider from "../ShopPages/Slider/Slider";
import Categories from "../dialog/Menus/Categories";
import ContentManager from "../dialog/Content/Content";
import Tests from "../dialog/Test/Tests";
import AchievementsManager from "../dialog/Achivments/Achivments";
import PsychologistsManager from "../dialog/Psychologists/PsychologistsManager";
import SessionCalendar from "../dialog/SessionCalendar/SessionCalendar";
import ClientsManager from "../dialog/Clients/ClientsManager";


export default function Main() {


    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

    const theme = useSelector((state) => state.theme.theme);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(login());

        } else {
            dispatch(logout());

        }
    }, [isLoggedIn])

    return<>
        <div className={'w-full h-full '}>
            {
                isLoggedIn ?
                    <Layout className={`w-full  h-screen ${theme===`dark`? `bg-black` : `bg-[#f3f5f6]`} overflow-hidden p-4`}>
                    <Sidebar/>
                    <Layout className={`w-full ${theme === `dark`? `bg-black`: `bg-[#f3f5f6]`}  px-4 rounded-3xl pb-6`}>
                        <Header className={`w-full ${theme === `dark`? `bg-[#4d4d4d]`: `bg-[#f3f5f6]`}  transition-all duration-500 px-4 flex flex-row items-center rounded-t-2xl `}>
                            {/*<Routes>*/}
                            {/*    <Route path={`/dashboard`} element={<Content_Header/>}/>*/}
                            {/*</Routes>*/}
                            <Content_Header/>
                        </Header>
                        <Content className={`${theme ===`dark`?`bg-[#4d4d4d]`:''} ${styles.content}`}>
                            <Routes>
                                <Route path={`/`} element={<DemoDashboard/>}/>

                                <Route path={`/demo_dash`} element={<Dashboard/>}/>

                                <Route path={'/admins'} element={<Admins/>}/>
                                <Route path={'/operators'} element={<Operators/>}/>
                                <Route path={'/drivers'} element={<Couriers/>}/>
                                <Route path={'/content'} element={<ContentManager/>}/>
                                <Route path={'/tests'} element={<Tests/>}/>

                                <Route path={'/cars'} element={<Cars/>}/>
                                <Route path={'/menus'} element={<Categories/>}/>

                                <Route path={'/driver_warehouse_bottles'} element={<DriverWarehouseBottle/>}/>
                                <Route path={'/driver_warehouse'} element={<DriverWarehouses/>}/>

                                <Route path={'/driver_cards'} element={<DriverCards/>}/>

                                <Route path={'/cards'} element={<Cards/>}/>

                                <Route path={'/history'} element={<History/>}/>

                                <Route path={'/cashback_history'} element={<CashbackHistory/>}/>

                                <Route path={'/warehouse'} element={<Warehouse/>}/>
                                <Route path={'/warehouse_bottle'} element={<WarehouseBottles/>}/>

                                <Route path={'/bottles_types'} element={<BottlesTypes/>}/>
                                <Route path={'/bottles'} element={<Bottles/>}/>
                                <Route path={'/images'} element={<ImageManager/>}/>
                                <Route path={'/language'} element={<Languages/>}/>
                                <Route path={'/icons'} element={<Icons/>}/>
                                <Route path={'/category_type'} element={<CategoryType/>}/>
                                <Route path={'/category'} element={<Category/>}/>
                                <Route path={'/brands'} element={<Brands/>}/>
                                <Route path={'/products'} element={<Products/>}/>
                                <Route path={'/orders'} element={<Orders/>}/>
                                <Route path={'/users'} element={<Users/>}/>
                                <Route path={'/clients'} element={<ClientsManager/>}/>
                                <Route path={'/warehouses'} element={<WarehouseProduct/>}/>
                                <Route path={'/drivers-cards'} element={<DriverCards/>}/>
                                <Route path={'/news'} element={<News/>}/>
                                <Route path={'/slider'} element={<Slider/>}/>
                                <Route path={'/achievements'} element={<AchievementsManager/>}/>
                                <Route path={'/psychologs'} element={<PsychologistsManager/>}/>
                                <Route path={'/sessions'} element={<SessionCalendar/>}/>
                            </Routes>
                        </Content>
                    </Layout>
                </Layout> : <Login/>
            }
        </div>
    </>
}
