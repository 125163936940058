import React, { useEffect, useState } from "react";
import {
    Button,
    Drawer,
    Form,
    Input,
    message,
    Modal,
    Table,
    Popconfirm,
} from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Excel } from "antd-table-saveas-excel";
import { SiMicrosoftexcel } from "react-icons/si";
import { useSelector } from "react-redux";
import {
    getPsychologists,
    showPsychologist,
    addPsychologist,
    updatePsychologist,
    deletePsychologist
} from "../../../api/dialog/psychologistsAPI";
import styles from "./style.module.css";
import TextArea from "antd/es/input/TextArea";

export default function PsychologistsManager() {
    const [edit, setEdit] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [psychologists, setPsychologists] = useState([]);
    const [filteredPsychologists, setFilteredPsychologists] = useState([]);
    const [selectedPsychologist, setSelectedPsychologist] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const theme = useSelector((state) => state.theme.theme);

    useEffect(() => {
        fetchPsychologists();
    }, []);

    const fetchPsychologists = async () => {
        setLoading(true);
        try {
            const data = await getPsychologists();
            setPsychologists(data);
            setFilteredPsychologists(data);
        } catch (error) {
            console.error('Error fetching psychologists:', error);
        }
        setLoading(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
        form.resetFields();
    };

    const handleOk = () => {
        form.submit();
    };

    const handleCancel = () => {
        setEdit(false);
        setIsModalOpen(false);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onFinish = async (values) => {
        try {
            if (edit) {
                await updatePsychologist(selectedPsychologist.id, values);
                message.success("Психолог успешно обновлен");
            } else {
                await addPsychologist(values);
                message.success("Психолог успешно добавлен");
            }
            await fetchPsychologists();
            setIsModalOpen(false);
        } catch (error) {
            console.log('Error updating/adding psychologist', error);
            message.error('Ошибка при сохранении психолога');
        }
    };

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Имя',
            dataIndex: 'first_name',
            key: 'first_name',
        },
        {
            title: 'Фамилия',
            dataIndex: 'last_name',
            key: 'last_name',
        },
        {
            title: 'Возраст',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: 'Телефон',
            dataIndex: 'phone_number',
            key: 'phone_number',
        },
        {
            title: 'Описание',
            dataIndex: 'about',
            key: 'about',
        },
        {
            title: 'Действия',
            render: (record) => (
                <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                    <div onClick={async () => {
                        const response = await showPsychologist(record.id);
                        setSelectedPsychologist(response);
                        setOpen(true);
                    }} className={'action_buttons bg-indigo-500 text-white'}>
                        <EyeOutlined />
                    </div>
                    <div onClick={() => {
                        setEdit(true);
                        setSelectedPsychologist(record);
                        form.setFieldsValue(record);
                        setIsModalOpen(true);
                    }} className={'action_buttons bg-amber-500 text-white'}>
                        <EditOutlined />
                    </div>

                    <Popconfirm
                        title="Удалить психолога"
                        description="Вы уверены, что хотите удалить этого психолога?"
                        onConfirm={async () => {
                            await deletePsychologist(record.id);
                            await fetchPsychologists();
                        }}
                        okText="Да"
                        cancelText="Нет"
                        okButtonProps={{ className: 'bg-red-600' }}
                    >
                        <div className={'action_buttons bg-red-600 text-white'}>
                            <DeleteOutlined />
                        </div>
                    </Popconfirm>
                </div>
            )
        }
    ];

    const handleExport = () => {
        const excel = new Excel();
        excel
            .addSheet("Psychologists")
            .addColumns(columns)
            .addDataSource(filteredPsychologists, {
                str2Percent: true
            })
            .saveAs("Psychologists.xlsx");
    };

    return (
        <div className={'w-full'}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        Психологи
                    </div>

                    <div className={styles.tools}>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <Button onClick={showModal} type="primary" className={'bg-green-600'}>
                                Добавить психолога +
                            </Button>

                            <Button type={"primary"} onClick={handleExport}
                                    className={'bg-blue-400 flex flex-row text-xl items-center'}>
                                <SiMicrosoftexcel />
                            </Button>
                        </div>

                        <div className={'flex flex-row items-center'}>
                            <div className={`font-sans font-semibold ${theme === 'dark' ? 'text-white' : 'text-black/60'} mr-4`}>Поиск</div>
                            <Input
                                placeholder="Введите текст для поиска"
                                allowClear
                                onChange={(e) => {
                                    const searchValue = e.target.value.toLowerCase();
                                    setFilteredPsychologists(psychologists.filter(psychologist =>
                                        psychologist.first_name.toLowerCase().includes(searchValue) ||
                                        psychologist.last_name.toLowerCase().includes(searchValue) ||
                                        psychologist.phone_number.toLowerCase().includes(searchValue)
                                    ));
                                }}
                                style={{ width: 200 }}
                            />
                        </div>
                    </div>
                </div>

                <Modal title="Заполните форму психолога" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <Form
                        form={form}
                        name="psychologistForm"
                        initialValues={edit ? selectedPsychologist : {}}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="first_name"
                            label="Имя"
                            rules={[{ required: true, message: 'Пожалуйста, введите имя!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="last_name"
                            label="Фамилия"
                            rules={[{ required: true, message: 'Пожалуйста, введите фамилию!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="age"
                            label="Возраст"
                            rules={[{ required: true, message: 'Пожалуйста, введите возраст!' }]}
                        >
                            <Input type="number" />
                        </Form.Item>

                        <Form.Item
                            name="phone_number"
                            label="Телефон"
                            rules={[{ required: true, message: 'Пожалуйста, введите номер телефона!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="about"
                            label="Описание"
                        >
                            <TextArea rows={4} />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            label="Пароль"
                            rules={[{ required: true, message: 'Пожалуйста, введите пароль!' }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            name="password_confirmation"
                            label="Подтвердите пароль"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Пожалуйста, подтвердите пароль!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Пароли не совпадают!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </Form>
                </Modal>

                <div className={`w-full ${theme === 'dark' ? 'custom_dark' : `custom_light`}`}>
                    <Table className={'shadow-2xl w-full rounded-xl'} dataSource={filteredPsychologists} columns={columns} />
                </div>

                <Drawer title="Психолог" onClose={onClose} open={open}>
                    <div className={'flex w-full flex-col items-center text-start gap-y-2'}>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <p className={'text-xl'}>Имя: </p>
                            <p className={'text-xl font-semibold'}>{selectedPsychologist?.first_name}</p>
                        </div>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <p className={'text-xl'}>Фамилия: </p>
                            <p className={'text-xl font-semibold'}>{selectedPsychologist?.last_name}</p>
                        </div>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <p className={'text-xl'}>Возраст: </p>
                            <p className={'text-xl font-semibold'}>{selectedPsychologist?.age}</p>
                        </div>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <p className={'text-xl'}>Телефон: </p>
                            <p className={'text-xl font-semibold'}>{selectedPsychologist?.phone_number}</p>
                        </div>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <p className={'text-xl'}>Описание: </p>
                            <p className={'text-xl font-semibold'}>{selectedPsychologist?.about}</p>
                        </div>
                    </div>
                </Drawer>
            </div>
        </div>
    );
}
