import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const contentAPI = axios.create({
    baseURL,
});

export const getContent = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await contentAPI.get(`${baseURL}api/contents`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const showContent = async (categoryId,id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await contentAPI.get(`${baseURL}api/categories/${categoryId}/contents/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const addContent = async (categoryId, data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.post(`${baseURL}api/categories/${categoryId}/contents`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const deleteContent = async (ContentID) => {
    const token = localStorage.getItem('token');
    try {
        const response = await contentAPI.delete(`${baseURL}api/contents/${ContentID}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const updateContent = async (ContentID, data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await contentAPI.put(`${baseURL}api/contents/${ContentID}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};
