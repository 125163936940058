import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const ClientsAPI = axios.create({
    baseURL,
});

export const getClients = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await ClientsAPI.get(`${baseURL}api/clients`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log(response.data);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const showClients = async (id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await ClientsAPI.get(`${baseURL}api/clients/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const addClients = async (data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await ClientsAPI.post(`${baseURL}api/clients`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const deleteClients = async (ClientsID) => {
    const token = localStorage.getItem('token');
    try {
        const response = await ClientsAPI.delete(`${baseURL}api/clients/${ClientsID}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const updateClients = async (ClientsID, data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await ClientsAPI.put(`${baseURL}api/clients/${ClientsID}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};
