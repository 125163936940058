import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Modal, Popconfirm, Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
    DialogGetBalls,
    DialogAddBall,
    DialogUpdateBall,
    DialogDeleteBall
} from '../../../api/dialog/ballAPI';

const { TextArea } = Input;

export default function BallManager({ contentId }) {
    const [balls, setBalls] = useState([]);
    const [selectedBall, setSelectedBall] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        fetchBalls();
    }, [contentId]);

    const fetchBalls = async () => {
        try {
            const data = await DialogGetBalls(contentId);
            setBalls(data);
        } catch (error) {
            console.error('Error fetching balls:', error);
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
        form.resetFields();
    };

    const handleOk = () => {
        form.submit();
    };

    const handleCancel = () => {
        setSelectedBall(null);
        setIsModalOpen(false);
    };

    const onFinish = async (values) => {
        try {
            if (selectedBall) {
                await DialogUpdateBall(contentId, selectedBall.id, values);
                message.success('Баллы успешно обновлены');
            } else {
                await DialogAddBall(contentId, values);
                message.success('Баллы успешно добавлены');
            }
            fetchBalls();
            setIsModalOpen(false);
        } catch (error) {
            console.error('Error updating/adding ball', error);
            message.error('Ошибка при сохранении баллов');
        }
    };

    const columns = [
        {
            title: 'Баллы',
            dataIndex: 'score',
            key: 'score',
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Действия',
            render: (record) => (
                <div className='action-buttons'>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => {
                            setSelectedBall(record);
                            form.setFieldsValue(record);
                            setIsModalOpen(true);
                        }}
                    />
                    <Popconfirm
                        title='Удалить баллы'
                        onConfirm={async () => {
                            await DialogDeleteBall(contentId, record.id);
                            fetchBalls();
                        }}
                        okText='Да'
                        cancelText='Нет'
                    >
                        <Button icon={<DeleteOutlined />} />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <div>
            <Button type='primary' onClick={showModal} className='mb-2'>
                Добавить баллы
            </Button>
            <Table dataSource={balls} columns={columns} rowKey='id' />
            <Modal
                title={selectedBall ? 'Редактировать баллы' : 'Добавить баллы'}
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form form={form} onFinish={onFinish}>
                    <Form.Item
                        name='score'
                        label='Баллы'
                        rules={[{ required: true, message: 'Пожалуйста, введите баллы' }]}
                    >
                        <Input type='number' />
                    </Form.Item>
                    <Form.Item
                        name='description'
                        label='Описание'
                        rules={[{ required: true, message: 'Пожалуйста, введите описание' }]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}
