import React, {useEffect, useState} from "react";
import styles from "./style.module.css";
import moment from 'moment';
import {
    Button, Carousel, DatePicker,
    Drawer,
    Form,
    Input, InputNumber,
    message,
    Modal, notification,
    Popconfirm, Popover, Select, Switch,
    Table, Typography
} from "antd";
import {
    CheckOutlined,
    ClockCircleFilled, ClockCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    InfoCircleOutlined,
    LeftOutlined, OrderedListOutlined, PauseCircleFilled, PhoneOutlined,
    RightOutlined, UserOutlined
} from "@ant-design/icons";
import {LiaProductHunt, LiaUserShieldSolid} from "react-icons/lia";
import {Excel} from "antd-table-saveas-excel";
import {SiMicrosoftexcel, SiSalesforce, SiShortcut} from "react-icons/si";

import {Option} from "antd/es/mentions";
import {useSelector} from "react-redux";
import {addLanguage, deleteLanguage, getLanguage, showLanguage, updateLanguage} from "../../../api/language";
import {addIcons, getIcons, updateIcons} from "../../../api/Icons_api";
import {getImages} from "../../../api/imageManager";
import Search from "antd/es/input/Search";
import {
    addCategory,
    deleteCategory,
    getCategory,
    getCategoryByLang,
    showCategory,
    updateCategory
} from "../../../api/Category_api";
import TextArea from "antd/es/input/TextArea";
import {getCategoryType} from "../../../api/categoryType_api";
import {addProducts, deleteProducts, getProducts, showProducts, updateProducts} from "../../../api/products_api";
import {number} from "prop-types";
import {getBrands} from "../../../api/Brands_api";
import {ImAddressBook, ImImage} from "react-icons/im";
import {BiCheckCircle, BiMoney, BiPauseCircle, BiTime} from "react-icons/bi";
import {FcSalesPerformance} from "react-icons/fc";
import {MdBookmarkBorder, MdOutlineDateRange} from "react-icons/md";
import {BsCalendar2Date} from "react-icons/bs";
import {HiOutlineInformationCircle, HiOutlineTrendingUp} from "react-icons/hi";
import {
    changeOrderCourier,
    completedOrder, deleteOrders,
    getOrders, getTotalHoldLastweekOrder,
    getTotalLastweekOrder, getTotalPendingLastweekOrder,
    holdOrder,
    makeOrder,
    pendingOrder
} from "../../../api/orders_api";
import {TbShoppingCartDollar} from "react-icons/tb";
import emptyProduct from '../../../assets/images/emptyProduct.jpg'
import {GrCircleInformation} from "react-icons/gr";
import {TiMediaEjectOutline} from "react-icons/ti";
import {AiOutlineClockCircle} from "react-icons/ai";
import {CgChevronDoubleDown, CgChevronDoubleUp} from "react-icons/cg";
import {getUser} from "../../../api/users_api";

export default function Orders() {

    const [edit, setEdit] = useState(false)

    const [isLoading, setLoading] = useState(false)

    const [bottles, setBottles] = useState([])
    const [bottlesOld, setBottlesOld] = useState([])
    const [selectedBottles, setSelectedBottles] = useState([]);
    const [images, setImages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPath, setSelectedPath] = useState('');
    const [open, setOpen] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [selectedlanguages, setSelectedLanguages] = useState([]);
    const [types, setTypes] = useState([]);
    const [brandId, setBrandId] = useState();
    const [brief, setBreif] = useState('');
    const [description, setDescription] = useState('');
    const [weekTotal, setWeekTotal] = useState([]);
    const [weekTotalPending, setWeekTotalPending] = useState([]);
    const [weekTotalHold, setWeekTotalHold] = useState([]);
    const [topIcons, setTopIcons] = useState([]);
    const [covers, setcovers] = useState([]);
    const [isIcon, setIsIcon] = useState(true)
    const [filteredImages, setFilteredImages] = useState([]);
    const [brands, setBrands] = useState([]);
    const [selectDescription, setSelectDescription] = useState();
    const [products, setProducts] = useState([]);
    const [clients, setClients] = useState([]);
    const [couriers, setCouriers] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isCheck, setIscheck] = useState(false);
    const [location, setLocation] = useState({latitude: '', longitude: ''});
    const [form] = Form.useForm();
    const currentDate = new Date();
    const [userSearchValue, setUserSearchValue] = useState('');
    const [courierSearchValue, setCourierSearchValue] = useState('');
    const [isModalOpen2, setIsModalOpen2] = useState(false);

    const [api, contextHolder] = notification.useNotification();
    const openNotification = (title, description, icon) => {
        api.open({
            message: title,
            description:
            description,
            icon: (
                icon
            ),
        });
    };

    const showModal2 = () => {
        setIsModalOpen2(true);
    };

    const handleOk2 = () => {
        setIsModalOpen2(false);
    };

    const handleCancel2 = () => {
        setIsModalOpen2(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit()
        setEdit(false)
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setEdit(false)
        setIsModalOpen(false);
    };
    const onClose = () => {
        setOpen(false);
    };

    const theme = useSelector((state) => state.theme.theme);


    useEffect(() => {
        fetchLanguage()
        fetchProducts()
        fetchBottles()
        fetchType()
        fetchImagees()
        fetchBrands()
        totalWeek()
        fetchClients()
        fetchCoruiers()

    }, [])

const totalWeek = async () => {
  try {
      const total = await getTotalLastweekOrder();
      const totalHold = await getTotalHoldLastweekOrder();
      const totalPending = await getTotalPendingLastweekOrder();
      setWeekTotal(total)
      setWeekTotalHold(totalHold)
      setWeekTotalPending(totalPending)
  } catch (e){
      console.log(e)
  }
}

    const fetchBrands = async () => {
        try {
            const data = await getBrands();
            setBrands(data);
            console.log(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
    }

    const fetchClients = async () => {
        try {
            const data = await getUser('user-clients');
            setClients(data);
            console.log(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
    }

    const fetchCoruiers = async () => {
        try {
            const data = await getUser('user-couriers');
            setCouriers(data);
            console.log(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
    }
    const getRowClassName = (record) => {
        switch (record.status) {
            case 'created':
                return 'bg-cyan-500';
            case 'completed':
                return 'bg-green-600';
            case 'pending':
                return 'bg-orange-400';
            default:
                return '';
        }
    };
    const fetchImagees = async () => {
        try {
            const data = await getImages();
            setFilteredImages(data)
            setImages(data);
            console.log(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
    }
    const fetchBottles = async () => {
        setLoading(true);
        try {
            const data = await getOrders();
            setBottles(data);
            setBottlesOld(data)
            console.log('orders')
            console.log(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
        setLoading(false);
    };
    function findCourierById(couriers, id) {
        return couriers.find(courier => courier.courier.id === id);
    }


    const formatProductsForServer = () => {
        return selectedProducts.map((product) => {
            console.log(selectedProducts);
            return {
                product_id: product.productId,
                count: product.count
            };
        });
    };
    const onFinish = async (values) => {
        const formattedDate = moment(values.date).format('YYYY-MM-DD');
        console.log("submit")
        console.log(formatProductsForServer())
        try {
            if (edit) {

                await updateProducts(selectedBottles.id, values);
                setBottles(bottles)
                message.success("Categoriya muvoffaqiyatli o`zgardi")
                await fetchBottles()
            } else {

                // values.date = formattedDate;
                // values.accept_date = moment(values.accept_date).format('YYYY-MM-DD');
                values.products = formatProductsForServer();
                values.status = 'on hold'
                console.log(JSON.stringify(values))
                await makeOrder(JSON.stringify(values));
                setBottles(bottles)
                message.success("Categoriya muvoffaqiyatli qo`shildi")
                await fetchBottles()
            }
        } catch (error) {
            console.log('Error adding bottles types ', error)
        }
    }

    const fetchProducts = async () => {
        try {
            const data = await getProducts('uz');
            setProducts(data)

        } catch (e) {
            console.log(e)
        }
    }

    const columns = [
        {
            title: '№',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Manzil',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Qo`shimcha ma`lumot',
            dataIndex: 'description',
            key: 'description',
            render: (description) => (
                <div>
                    {description && description.length > 20 ? (
                        <Popover className={'cursor-pointer bg-green-600 rounded p-1 text-white'} placement="rightTop" title={'To`liq ma`lumot'} content={description}>
                            {`${description.substring(0, 20)}...`}
                        </Popover>
                    ) : description}

                </div>
            )
        },
        {
            title: 'Foydalanuvchi',
            dataIndex: 'user',
            key: 'user',
            render: user => (
                <div
                    className={'cursor-pointer bg-blue-500 p-[5px] text-white rounded flex flex-row items-center w-fit'}>
                    <GrCircleInformation className={'text-yellow-400 mr-2'}/>
                    <Popover placement="rightTop" title={`Foydalanuvchi : ${user.fullname}`} content={() => (
                        <div className={'flex flex-col'}>
                            Ismi : {user.fullname}
                            <br/>
                           <p className={'bg-blue-400 rounded text-white cursor-pointer'}  onClick={()=>{
                               navigator.clipboard.writeText(user['phoneNumber'])
                               message.success('Qo`l telefoni saqlandi ' + user['phoneNumber'])
                           }}> Telefon raqami : {user.phoneNumber}</p>

                            Manzili : {user.address}
                        </div>
                    )}> {user.fullname}</Popover>
                </div>
            )

        },
        {
            title: 'Kuryer',
            dataIndex: ['courier_id','id'],
            key:'courier_id',
            render:((text,record)=>(
                <div>
                    <Select
                        className={'w-[200px]'}
                        onChange={async (e) => {
                            await  changeOrderCourier(record.id,e)
                            fetchBottles()
                            message.success('Kuryer muvoffaqiyatli o`zgartirildi')
                        }}
                        showSearch
                        filterOption={(input, option) => {
                            const userFullName = option.children ? (typeof option.children === 'string' ? option.children.toLowerCase() : '') : '';
                            const userPhoneNumber = option.key ? option.key.toLowerCase() : ''; // Используем option.key вместо option.value
                            const searchValue = input.toLowerCase();

                            const fullNameMatch = userFullName.includes(searchValue);
                            const phoneNumberMatch = userPhoneNumber.includes(searchValue);

                            return fullNameMatch || phoneNumberMatch;
                        }}
                        onSearch={value => setCourierSearchValue(value)}
                        value={record.courier_id} // Объединяем courier_id и id в одно значение
                        placeholder={'Курьер не выбран'}
                    >
                        {couriers && couriers.length > 0 &&
                        couriers
                            .filter(user => user.courier.fullname.toLowerCase().includes(courierSearchValue.toLowerCase()) || user.courier.phoneNumber.toLowerCase().includes(courierSearchValue.toLowerCase()))
                            .map((user) => (
                                <Option key={user.courier.id} value={user.courier.id} >
                                    {' ismi : ' + user.courier.fullname + ' tel: ' + user.courier.phoneNumber}
                                </Option>
                            ))
                        }
                    </Select>
                </div>


            ))
        },
        {
            title: (
                <div className={'flex flex-row items-center w-full bg-orange-400 text-white rounded cursor-pointer'}>
                    <Popover title={'Qo`shimcha ma`lumot xolatlar bo`yicha '} content={() => (
                        <div className={'flex flex-col'}>
                            <p className={'bg-red-600 text-white m-1 rounded p-[3px]'}> on hold Bu buyurtma yaratilgan
                                lekin kutish vaqtida </p>
                            <p className={'bg-orange-600 text-white m-1 rounded p-[3px]'}> pending Bu buyurtma qabul
                                qilingan </p>
                            <p className={'bg-green-600 text-white m-1 rounded p-[3px]'}> complated Bu buyurtma yetqazib
                                berilgan </p>


                        </div>
                    )} className={'flex flex-row items-center cursor-pointer w-full'}>
                        <HiOutlineInformationCircle className={'text-[16px] mr-2 ml-2'}/>
                        Holati
                    </Popover>
                </div>
            ),
            dataIndex: ['status', 'id', 'user', 'address'],
            key: 'status',
            render: (text, record) => (
                <Select onChange={async (e) => {
                    switch (e) {
                        case 'on hold':
                            try {
                                const hold = await holdOrder(record.id)
                                openNotification(`Buyurtma № = ${record.id}  kutish vaqtiga o'zgardi`, (
                                    <div className={'flex p-2 rounded text-white text-[12px] flex-col bg-red-400'}>
                                        <p className={'flex flex-row items-center border-b-2'}>
                                            <OrderedListOutlined className={'m-2 text-[16px]'}/> Buyurtma tartib raqami
                                            : {record.id}
                                        </p>
                                        <p className={'flex flex-row items-center border-b-2'}>
                                            <UserOutlined className={'m-2 text-[16px]'}/> Foydalanuvchi
                                            : {record.user['fullname']}
                                        </p>
                                        <p className={'flex flex-row items-center border-b-2 cursor-pointer bg-blue-600/40 rounded'} onClick={()=>{
                                            navigator.clipboard.writeText(record.user['phoneNumber'])
                                            message.success('Qo`l telefoni saqlandi ' + record.user['phoneNumber'])
                                        }}>
                                            <PhoneOutlined className={'m-2 text-[16px]'}/> Foydalanuvchi
                                            : {record.user['phoneNumber']}
                                        </p>
                                        <p className={'flex flex-row items-center border-b-2'}>
                                            <ImAddressBook className={'m-2 text-[16px]'}/> Manzil : {record.user['address']}
                                        </p>
                                    </div>

                                ), (<PauseCircleFilled
                                    style={{
                                        color: '#f55273',
                                    }}
                                />))
                            } catch (e) {
                                message.error('Xatolik yuz berdi')
                                console.log(e)
                            }

                            break
                        case 'pending':
                            try {

                                const pending = await pendingOrder(record.id)
                            openNotification(`Buyurtma № = ${record.id}  Qabulga vaqtiga o'zgardi`, (
                                <div className={'flex p-2 rounded text-white text-[12px] flex-col bg-orange-400'}>
                                    <p className={'flex flex-row items-center border-b-2'}>
                                        <OrderedListOutlined className={'m-2 text-[16px]'}/> Buyurtma tartib raqami
                                        : {record.id}
                                    </p>
                                    <p className={'flex flex-row items-center border-b-2'}>
                                        <UserOutlined className={'m-2 text-[16px]'}/> Foydalanuvchi
                                        : {record.user['fullname']}
                                    </p>
                                    <p className={'flex flex-row items-center border-b-2 cursor-pointer bg-blue-600/40 rounded'} onClick={()=>{
                                        navigator.clipboard.writeText(record.user['phoneNumber'])
                                        message.success('Qo`l telefoni saqlandi ' + record.user['phoneNumber'])
                                    }}>
                                        <PhoneOutlined className={'m-2 text-[16px]'}/> Foydalanuvchi
                                        : {record.user['phoneNumber']}
                                    </p>
                                    <p className={'flex flex-row items-center border-b-2'}>
                                        <ImAddressBook className={'m-2 text-[16px]'}/> Manzil : {record.user['address']}
                                    </p>
                                </div>

                            ), (<AiOutlineClockCircle
                                style={{
                                    color: '#f5a952',
                                }}
                            />))
                            }catch (e) {
                                message.error('Xatolik yuz berdi'
                                )
                            }
                            break
                        case 'completed':
                            const completed = await completedOrder(record.id)
                            openNotification(`Buyurtma № = ${record.id}  Yetkazib berildiga o'zgardi`, (
                                <div className={'flex p-2 rounded text-white text-[12px] flex-col bg-green-600'}>
                                    <p className={'flex flex-row items-center border-b-2'}>
                                        <OrderedListOutlined className={'m-2 text-[16px]'}/> Buyurtma tartib raqami
                                        : {record.id}
                                    </p>
                                    <p className={'flex flex-row items-center border-b-2'}>
                                        <UserOutlined className={'m-2 text-[16px]'}/> Foydalanuvchi
                                        : {record.user['fullname']}
                                    </p>
                                    <p className={'flex flex-row items-center border-b-2 cursor-pointer bg-blue-600/40 rounded'} onClick={()=>{
                                        navigator.clipboard.writeText(record.user['phoneNumber'])
                                        message.success('Qo`l telefoni saqlandi ' + record.user['phoneNumber'])
                                    }}>
                                        <PhoneOutlined className={'m-2 text-[16px]'}/> Foydalanuvchi
                                        : {record.user['phoneNumber']}
                                    </p>
                                    <p className={'flex flex-row items-center border-b-2'}>
                                        <ImAddressBook className={'m-2 text-[16px]'}/> Manzil : {record.user['address']}
                                    </p>
                                </div>

                            ), (<CheckOutlined
                                style={{
                                    color: '#68f552',
                                }}
                            />))
                            break
                    }
                }} className={'w-[130px] p-0'} defaultValue={record['status']}>
                    <option className={'text-[10px] '} value={'on hold'}>
                        <div
                            className={'flex flex-row items-center justify-center bg-red-600 w-full rounded text-white'}>
                            <BiPauseCircle style={{marginRight: '5px'}}/>
                            on hold
                        </div>
                    </option>
                    <option value={'pending'}>
                        <div
                            className={'flex flex-row items-center justify-center bg-orange-600 w-full rounded text-white'}>
                            <BiTime style={{marginRight: '5px'}}/>
                            pending
                        </div>
                    </option>
                    <option value={'completed'}>
                        <div
                            className={'flex flex-row items-center justify-center bg-green-600 w-full rounded text-white'}>
                            <BiCheckCircle style={{marginRight: '5px'}}/>
                            completed
                        </div>
                    </option>
                </Select>
            )
        },
        {
            title: 'Umumiy summa',
            dataIndex: 'total',
            key: 'total',
            render: (total) => (
                <p className={'bg-green-600 rounded p-[2px] text-white w-fit text-[12px]'}>{total} so`m</p>
            )

        },

        {
            title: 'Actions',
            render: (record) => {
                return <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                    <div onClick={async () => {
                        const response = await showProducts(record.id);
                        setSelectedBottles(response)
                        setOpen(true)
                    }} className={'action_buttons bg-indigo-500 text-white'}>
                        <EyeOutlined/>
                    </div>
                    <div onClick={() => {
                        setEdit(true)
                        setSelectedBottles(record)
                    }} className={'action_buttons bg-amber-500 text-white'}>
                        <EditOutlined/>
                    </div>

                    <Popconfirm
                        title="Maxsulotni o`chirish "
                        description="Haqiqatdan ham o`chirishni xohlaysizmi ?"
                        onConfirm={async () => {

                            await deleteOrders(record.id)
                            await fetchBottles()
                        }
                        }
                        okText="ha"
                        cancelText="yo`q"
                        okButtonProps={deleteButton}
                    >
                        <div className={'action_buttons bg-red-600 text-white'}>
                            <DeleteOutlined/>
                        </div>
                    </Popconfirm>


                </div>
            }
        }
    ];

    const columns2 = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'code',
            dataIndex: 'code',
            key: 'code',
        },

    ];

    const handleClick = () => {
        const excel = new Excel();
        excel
            .addSheet("test")
            .addColumns(columns2)
            .addDataSource(bottles, {
                str2Percent: true
            })
            .saveAs("Excel.xlsx");
    };

    const handleSearch = (searchValue) => {
        const filtered = images.filter((value) => {
            return (
                value.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                value.img_category.toLowerCase().includes(searchValue.toLowerCase())
            );
        });
        setFilteredImages(filtered);
    };

    useEffect(() => {
    }, [selectedProducts]);
    const totalPrice = (isCheck) => {
        if (isCheck) {
            let total = 0;
            let elements = [];
            selectedProducts.forEach(value => {
                const product = findProductById(value?.productId);
                const subtotal = value?.count * product?.price;
                total += subtotal;
                elements.push(
                    <div key={value?.productId} className={'text-black border-b-2 rounded'}>
                        {`${product?.name} soni: ${value?.count}, umumiy narxi: ${subtotal} so'm`}
                    </div>
                );
            });
            return elements;
        } else {
            let total = 0;
            selectedProducts.forEach(value => {
                const product = findProductById(value?.productId);
                total += value?.count * product?.price;
            });
            return total;
        }
    };

    const fetchLanguage = async () => {
        try {
            const data = await getLanguage()
            console.log(data)
            setLanguages(data)
        } catch (e) {

        }

    }
    const fetchType = async () => {
        try {
            const data = await getCategoryType()
            console.log(data)
            setTypes(data)
        } catch (e) {

        }
    }
    useEffect(() => {

    }, [bottles])

    const onSearch = (values) => {

        if (values.target.value.length === 0) {
            setBottles(bottlesOld)
        } else {
            setBottles(filterBottles(values?.target?.value.toLowerCase()))
        }

    }

    const iconsHandler = (value) => {
        let newIcons;
        // Создаем новый массив на основе текущего состояния topIcons или covers
        if (!isIcon) {
            newIcons = [...topIcons];
        } else {
            newIcons = [...covers];
        }
        const index = newIcons.indexOf(value);

        if (index === -1) {
            // Если значение не найдено в массиве, добавляем его
            newIcons.push(value);
        } else {
            // Если значение найдено в массиве, удаляем его
            newIcons.splice(index, 1);
        }

        // Обновляем состояние topIcons или covers новым массивом в зависимости от условия
        !isIcon ? setTopIcons(newIcons) : setcovers(newIcons);
        console.log(topIcons);
        console.log(covers);
    };


    // axios.put(baseUrl, data)
    function filterBottles(value) {
        return bottles.filter(bottle => {
            return (bottle.address && bottle.address.toLowerCase().includes(value.toLowerCase())) ||
                (bottle.user && bottle.user.fullname && bottle.user.fullname.toLowerCase().includes(value.toLowerCase())) ||
                (bottle.user && bottle.user.phoneNumber && bottle.user.phoneNumber.toLowerCase().includes(value.toLowerCase())) ||
                (bottle.id && bottle.id.toString().includes(value)) ||
                (bottle.status && bottle.status.toLowerCase().includes(value.toLowerCase()));



        });
    }

    const deleteButton = {
        style: {
            backgroundColor: '#c00900', // Set the default background color
        },
        // Add other props as needed
    };

    const confirmButtonProps = {
        style: {
            backgroundColor: '#0c75ff', // Set the default background color
        },
        // Add other props as needed
    };
    const handleLanguage = async (e) => {
        setLoading(true);
        try {
            const data = await getProducts(e);
            setBottles(data);
            setBottlesOld(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
        setLoading(false);

    }

    const handleProductChange = (productId, count) => {
        const updatedProducts = [...selectedProducts];
        const index = updatedProducts.findIndex((product) => product.productId === productId);

        if (index !== -1) {
            // Если продукт уже выбран, обновляем его количество
            updatedProducts[index] = {productId, count};
        } else {
            // Если продукт еще не выбран, добавляем его в список
            updatedProducts.push({productId, count});
        }

        setSelectedProducts(updatedProducts);
    };
    const findProductById = (productId) => {
        return products.find((product) => product.id === productId);
    };
    const search = (value) => {
        message.info(value)
    }
    return <div className={'w-full'}>
        {contextHolder}
        <div className={styles.container}>
            <div className={styles.header_bottles}>
                <div className={'flex flex-row w-full'}>
                    <div className={styles.title}>
                        Buyurtmalar
                        <MdBookmarkBorder className={'text-[28px]'}/>
                    </div>
                    <div
                        className={'w-[200px] h-[50px] rounded bg-green-800/30 self-center align-center ml-12 flex flex-row items-center'}>
                        <HiOutlineTrendingUp className={'text-4xl text-green-600 bg-white/30 rounded ml-2'}/>
                        <div className={'flex flex-col text-green-600 ml-2 font-bold'}>
                            Kirim :
                            <div>
                                {weekTotal.total_earnings_last_7_days} so`m
                            </div>
                        </div>

                    </div>

                    <div
                        className={'w-[200px] h-[50px] rounded bg-orange-800/30 self-center align-center ml-12 flex flex-row items-center'}>
                        <CgChevronDoubleUp className={'text-4xl text-orange-600 bg-white/30 rounded ml-2'}/>
                        <div className={'flex flex-col text-orange-600 ml-2 font-bold'}>
                            Bajarilmoqda :
                            <div>
                                {weekTotalPending.total_earnings_last_7_days} so`m
                            </div>
                        </div>

                    </div>


                <div
                    className={'w-[200px] h-[50px] rounded bg-red-800/30 self-center align-center ml-12 flex flex-row items-center'}>
                    <CgChevronDoubleDown className={'text-4xl text-red-600 bg-white/30 rounded ml-2'}/>
                    <div className={'flex flex-col text-red-600 ml-2 font-bold'}>
                        Qabul qilinmagan :
                        <div>
                            {weekTotalHold.total_earnings_last_7_days} so`m
                        </div>
                    </div>

                </div>
            </div>
                <div className={styles.tools}>
                    <div className={'flex flex-row items-center gap-x-2'}>
                        <Button
                            onClick={showModal}
                            type="primary"
                            className={'bg-green-600'}
                        >
                            Buyurtmani qo`shish +
                        </Button>

                        <Button type={"primary"} onClick={() => {
                            handleClick()
                        }}
                                className={'bg-blue-400 flex flex-row text-xl items-center'}><SiMicrosoftexcel/>
                        </Button>
                    </div>

                    <div className={'flex flex-row bg-blue-800 text-white rounded p-1'}>
                        Oxirgi yangilangan sana va vaqt:
                        <ClockCircleOutlined className={'ml-4 mr-4'}/>
                        {'      ' + currentDate.toLocaleTimeString() + '      '}
                        <BsCalendar2Date className={'ml-2 mr-2'}/>
                        {currentDate.toLocaleDateString()}

                    </div>

                    <div className={'flex flex-row items-center'}>

                        <div
                            className={`font-sans font-semibold ${theme === 'dark' ? 'text-white' : 'text-black/60'} text-black/60 mr-4`}>Izlash
                        </div>
                        <Input
                            placeholder="input search text"
                            allowClear
                            onChange={onSearch}
                            style={{
                                width: 200,
                            }}
                        />
                    </div>

                </div>

            </div>

            <Modal title="Buyurtmalar" open={isModalOpen || edit} okButtonProps={confirmButtonProps}
                   cancelText={'Ortga'} okText={'Tasdiqlash'}
                   onOk={handleOk} onCancel={handleCancel} bodyStyle={{height: '550px', overflow: 'scroll'}}>
                <Form
                    form={form}
                    name="Buyurtma yaratish"
                    initialValues={{remember: true, cashbackPercent: 0}}
                    onFinish={onFinish}

                >

                    {/*<Form.Item name="date" label="Date">*/}
                    {/*    <DatePicker/>*/}
                    {/*</Form.Item>*/}

                    <Form.Item
                        label="Foydalanuvchi"
                        name="user_id"
                        rules={[
                            {
                                required: true,
                                message: 'Iltimos foydalanuvchini tanlang!',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            filterOption={(input, option) => {
                                const userFullName = option.children.toLowerCase();
                                const userPhoneNumber = option.value.toLowerCase();
                                const searchValue = input.toLowerCase(); // используем input для фильтрации

                                return userFullName.includes(searchValue) || userPhoneNumber.includes(searchValue);
                            }}
                            onSearch={value => setUserSearchValue(value)} // Обновляем userSearchValue при изменении значения в поисковой строке
                        >
                            {clients?.map((user) => (
                                <Option key={user.id} value={user.id}>
                                    {user.fullname + ' tel: ' + user.phoneNumber}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>


                    <Form.Item name="address" label="Address">
                        <Input/>
                    </Form.Item>


                    {/*<Form.Item name="accept_date" label="Accept Date">*/}
                    {/*    <DatePicker/>*/}
                    {/*</Form.Item>*/}

                    <Form.Item name="description" label="Description">
                        <Input.TextArea/>
                    </Form.Item>

                    {/*<Form.Item name="total" label="Total">*/}
                    {/*    <Input type={number}/>*/}
                    {/*</Form.Item>*/}

                    {/*<Form.Item name="cashback" label="Cashback">*/}
                    {/*    <Input type={number}/>*/}
                    {/*</Form.Item>*/}
                    <Form.Item name="products" label="Products">
                        <Select
                            mode="multiple"
                            placeholder="Maxsulotni tanlash"
                            value={selectedProducts.map((product) => product.productId)}
                            onChange={(values) => setSelectedProducts(values.map((productId) => ({
                                productId,
                                count: 1
                            })))}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            } // Фильтруем опции по имени продукта
                        >
                            {products.map((product) => (
                                <Option key={product.id} value={product.id}>
                                    {`${product.name} - $${product.price}`}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <div className={'text-white bg-blue-400 rounded pl-1 mb-2 flex flex-col'}>
                        <div className={'flex flex-row justify-between'}>
                            <TbShoppingCartDollar className={'text-white text-[24px]'}/> Buyurtma summasi
                            : {totalPrice(false)} so`m
                            <Switch onChange={() => setIscheck(!isCheck)} className={'bg-blue-800 text-[10px] self-end'}
                                    checkedChildren={'Chek'} unCheckedChildren={'Maxsulot'}/>
                        </div>
                        {isCheck ? <div className={'flex flex-col bg-white w-[97%]  rounded mt-2 mb-2'}>
                            {totalPrice(true)}
                        </div> : ''}
                    </div>
                    {/* Добавляем поля для ввода количества для каждого выбранного продукта */}
                    <div className={'flex flex-row h-[300px] overflow-scroll border rounded-lg border-blue-400'}>

                        {selectedProducts.length === 0 ?
                            <div className={'relative '}>
                                <span className={'absolute text-4xl w-full top-[25%] left-[50%] font-bold'}>Maxsulotlar tanlanmagan</span>
                                <img src={emptyProduct} className=" inset w-full h-full opacity-50"/>

                            </div>
                            : selectedProducts.map((product) => (
                                <Form.Item key={product.productId}
                                           className={'flex flex-col m-1 border rounded-lg h-[130px] cursor-pointer duration-600 p-1 hover:border-blue-600'}>
                                    <div className={'flex flex-col items-center'}>
                                        <img
                                            src={`https://api.osonexpress.uz/${findProductById(product?.productId)?.cover[0]}`}
                                            width={50} height={50}/>
                                        <p className={'text-white text-[10px] rounded rounded p-[2px] bg-blue-500 font-semibold mt-1 mb-2'}>{findProductById(product.productId).name}</p>
                                        <InputNumber
                                            addonBefore={'so`m'}
                                            min={1}
                                            value={product?.count} // Используйте значение количества продукта из состояния
                                            onChange={(value) => handleProductChange(product.productId, value)}
                                        />
                                    </div>
                                </Form.Item>
                            ))}
                    </div>


                </Form>


            </Modal>
            <div className={`w-full ${theme === 'dark' ? 'custom_dark' : `custom_light`}`}>
                <Table className={'shadow-2xl w-full  rounded-xl'}  rowClassName={getRowClassName} dataSource={bottles} columns={columns}/>
            </div>

            <Drawer title={`${selectedBottles?.name}`} onClose={onClose} open={open}>
                <Typography
                    className={' self-start ml-2  bg-blue-700 text-white text-center rounded mb-2'}>Muqovalar </Typography>
                <div className={'flex w-full flex-col items-center text-start gap-y-2'}>

                    <Carousel style={{width: '300px', borderRadius: "10px"}}>
                        {
                            selectedBottles?.cover?.map(value =>
                                <div>
                                    <img src={`https://api.osonexpress.uz/${value}`} className={'w-full h-[300px]'}/>
                                </div>
                            )
                        }
                    </Carousel>
                    <Typography className={' self-start ml-2 text-3xl text-gray-400'}>Yuqori iconkalar </Typography>
                    <div
                        className={'flex flex-row flex-wrap w-full items-center border-2 border-blue-700 rounded-lg p-2'}>

                        {
                            selectedBottles?.topicons?.map(value =>
                                <img src={`https://api.osonexpress.uz/${value}`}
                                     className={'w-[40px] h-[40px] inline-block m-1'}/>
                            )
                        }

                    </div>
                    <div className={'flex flex-row w-full'}>
                        <BiMoney className={'text-green-600 text-4xl'}/>
                        <p className={'text-[13px]  ml-2 bg-green-800 text-white rounded-lg p-1 mt-1'}>Maxsulot narxi
                            : {selectedBottles?.price} so`m</p>
                    </div>
                    <div className={'flex flex-row w-full'}>
                        <FcSalesPerformance className={'text-green-600 text-4xl self-start'}/>
                        <p className={`text-[13px]  ml-2 ${selectedBottles.isSale ? 'text-teal-600' : 'text-red-700'} font-semibold  rounded border border-blue-700  rounded-lg p-1 mt-1`}>Aksiya </p>
                    </div>
                    <Typography className={' self-start ml-2 text-3xl text-gray-400'}>To`liq ma`lumot </Typography>

                    <div className={'bg-blue-800 text-white text-[14px] w-full p-4 rounded-lg'}>
                        <p className={'text-white text-[14px]'}>
                            {selectedBottles?.description}
                        </p>
                    </div>
                    <Typography className={' self-start ml-2 text-3xl text-gray-400'}>Qisqacha ma`lumot </Typography>

                    <div className={'bg-blue-800 text-white text-[14px] w-full p-4 rounded-lg'}>
                        <p className={'text-white text-[14px]'}>
                            {selectedBottles?.brief_description}
                        </p>
                    </div>


                </div>


            </Drawer>
        </div>
        <Modal
            title="To`liq Ma`lumot"
            open={isModalOpen2}
            onOk={handleOk2}
            onCancel={handleCancel2}
            cancelText={'ortga'}
            okText={'tasdiqlash'}
            okButtonProps={{style: {backgroundColor: '#3742fa'}}} // Зеленый цвет для кнопки "Ok"
            cancelButtonProps={{
                style: {
                    backgroundColor: '#7bed9f',
                    color: 'white'
                }
            }} // Красный цвет для кнопки "Cancel"
        >
            <p>{selectDescription}</p>
        </Modal>
    </div>
}
