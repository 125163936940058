import React, { useEffect, useState } from "react";
import {
    Button,
    Drawer,
    Form,
    Input,
    message,
    Modal,
    Select,
    Table,
    Popconfirm,
} from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Excel } from "antd-table-saveas-excel";
import { SiMicrosoftexcel } from "react-icons/si";
import { useSelector } from "react-redux";
import {
    getAchievements,
    showAchievement,
    addAchievement,
    updateAchievement,
    deleteAchievement
} from "../../../api/dialog/achievementsAPI";
import { getImages } from "../../../api/imageManager";
import styles from "./style.module.css";
import TextArea from "antd/es/input/TextArea";
import Search from "antd/es/input/Search";

const { Option } = Select;

export default function AchievementsManager() {
    const [edit, setEdit] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [achievements, setAchievements] = useState([]);
    const [filteredAchievements, setFilteredAchievements] = useState([]);
    const [selectedAchievement, setSelectedAchievement] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const theme = useSelector((state) => state.theme.theme);
    const [images, setImages] = useState([]);
    const [coverPath, setSelectedCoverPath] = useState('');
    const [filteredImages, setFilteredImages] = useState([]);

    useEffect(() => {
        fetchAchievements();
        fetchImages();
    }, []);

    const api_url = process.env.REACT_APP_URL;

    const fetchImages = async () => {
        try {
            const data = await getImages();
            setFilteredImages(data);
            setImages(data);
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    const fetchAchievements = async () => {
        setLoading(true);
        try {
            const data = await getAchievements();
            setAchievements(data);
            setFilteredAchievements(data);
        } catch (error) {
            console.error('Error fetching achievements:', error);
        }
        setLoading(false);
    };

    const handleSearch = (searchValue) => {
        const filtered = images.filter((value) => {
            return value?.name?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setFilteredImages(filtered);
    };

    const showModal = () => {
        setIsModalOpen(true);
        form.resetFields();
    };

    const handleOk = () => {
        form.submit();
    };

    const handleCancel = () => {
        setEdit(false);
        setIsModalOpen(false);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onFinish = async (values) => {
        try {
            values.icon = coverPath;
            if (edit) {
                await updateAchievement(selectedAchievement.id, values);
                message.success("Достижение успешно обновлено");
            } else {
                await addAchievement(values);
                message.success("Достижение успешно добавлено");
            }
            await fetchAchievements();
            setIsModalOpen(false);
        } catch (error) {
            console.log('Error updating/adding achievement', error);
            message.error('Ошибка при сохранении достижения');
        }
    };

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Название',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Иконка',
            dataIndex: 'icon',
            key: 'icon',
            render: (icon) => (
                <img src={`${api_url}${icon}`} alt="icon" style={{ width: 50, height: 50 }} />
            ),
        },
        {
            title: 'Действия',
            render: (record) => (
                <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                    <div onClick={async () => {
                        const response = await showAchievement(record.id);
                        setSelectedAchievement(response);
                        setOpen(true);
                    }} className={'action_buttons bg-indigo-500 text-white'}>
                        <EyeOutlined />
                    </div>
                    <div onClick={() => {
                        setEdit(true);
                        setSelectedAchievement(record);
                        form.setFieldsValue(record);
                        setIsModalOpen(true);
                    }} className={'action_buttons bg-amber-500 text-white'}>
                        <EditOutlined />
                    </div>

                    <Popconfirm
                        title="Удалить достижение"
                        description="Вы уверены, что хотите удалить это достижение?"
                        onConfirm={async () => {
                            await deleteAchievement(record.id);
                            await fetchAchievements();
                        }}
                        okText="Да"
                        cancelText="Нет"
                        okButtonProps={{ className: 'bg-red-600' }}
                    >
                        <div className={'action_buttons bg-red-600 text-white'}>
                            <DeleteOutlined />
                        </div>
                    </Popconfirm>
                </div>
            )
        }
    ];

    const handleExport = () => {
        const excel = new Excel();
        excel
            .addSheet("Achievements")
            .addColumns(columns)
            .addDataSource(filteredAchievements, {
                str2Percent: true
            })
            .saveAs("Achievements.xlsx");
    };

    return (
        <div className={'w-full'}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        Достижения
                    </div>

                    <div className={styles.tools}>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <Button onClick={showModal} type="primary" className={'bg-green-600'}>
                                Добавить достижение +
                            </Button>

                            <Button type={"primary"} onClick={handleExport}
                                    className={'bg-blue-400 flex flex-row text-xl items-center'}>
                                <SiMicrosoftexcel />
                            </Button>
                        </div>

                        <div className={'flex flex-row items-center'}>
                            <div className={`font-sans font-semibold ${theme === 'dark' ? 'text-white' : 'text-black/60'} mr-4`}>Поиск</div>
                            <Input
                                placeholder="Введите текст для поиска"
                                allowClear
                                onChange={(e) => {
                                    const searchValue = e.target.value.toLowerCase();
                                    setFilteredAchievements(achievements.filter(achievement =>
                                        achievement.title.toLowerCase().includes(searchValue)
                                    ));
                                }}
                                style={{ width: 200 }}
                            />
                        </div>
                    </div>
                </div>

                <Modal title="Заполните форму достижения" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <Form
                        form={form}
                        name="achievementForm"
                        initialValues={edit ? selectedAchievement : {}}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="title"
                            label="Заголовок"
                            rules={[{ required: true, message: 'Пожалуйста, введите заголовок!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label="Описание"
                            rules={[{ required: true, message: 'Пожалуйста, введите описание!' }]}
                        >
                            <TextArea rows={4} />
                        </Form.Item>

                        <div className={'flex flex-row flex-wrap'}>
                            <Search
                                placeholder={'Поиск по названию'}
                                style={{ width: '100%' }}
                                onSearch={handleSearch}
                            />
                        </div>

                        <div className={'flex flex-row w-full flex-wrap mt-1 rounded-lg border border-blue-700 p-2 h-[400px] overflow-scroll'}>
                            {filteredImages.map(value => (
                                <div className={'flex flex-col'} key={value.id}>
                                    <img
                                        onClick={() => setSelectedCoverPath(value.path)}
                                        src={`${api_url}${value.path}`}
                                        alt={value?.name}
                                        className={`w-20 h-20 flex cursor-pointer m-1 ${coverPath === value.path ? 'border-2 p-1 rounded-lg shadow-2xl border-blue-800' : ''}`}
                                    />
                                    <p className={`text-[12px] font-bold ${coverPath === value.path ? 'text-white duration-200 bg-blue-800 rounded-lg' : ''} text-blue-700 text-center`}>{value.name}</p>
                                </div>
                            ))}
                        </div>
                    </Form>
                </Modal>

                <div className={`w-full ${theme === 'dark' ? 'custom_dark' : `custom_light`}`}>
                    <Table className={'shadow-2xl w-full rounded-xl'} dataSource={filteredAchievements} columns={columns} />
                </div>

                <Drawer title="Достижение" onClose={onClose} open={open}>
                    <div className={'flex w-full flex-col items-center text-start gap-y-2'}>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <p className={'text-xl'}>Название: </p>
                            <p className={'text-xl font-semibold'}>{selectedAchievement?.title}</p>
                        </div>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <p className={'text-xl'}>Описание: </p>
                            <p className={'text-xl font-semibold'}>{selectedAchievement?.description}</p>
                        </div>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <p className={'text-xl'}>Иконка: </p>
                            <img src={`${api_url}${selectedAchievement?.icon}`} alt="icon" style={{ width: 50, height: 50 }} />
                        </div>
                    </div>
                </Drawer>
            </div>
        </div>
    );
}
