import React, { useEffect, useRef, useState } from "react";
import SimplePeer from "simple-peer";
import { message } from "antd";
import { MdOutlineWifiCalling } from "react-icons/md";

const VideoChat = ({ socket, recipientId, calling, callerSignal, caller, receivingCall }) => {
    const [stream, setStream] = useState(null);

    const [callAccepted, setCallAccepted] = useState(false);
    const [callEnded, setCallEnded] = useState(false);
    const [loading, setLoading] = useState(false);

    const userVideo = useRef();
    const partnerVideo = useRef();
    const connectionRef = useRef();

    useEffect(() => {
        const myId = localStorage.getItem('my_id');
        if (!myId) {
            console.error("User ID not found in localStorage");
            return;
        }

        navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
            setStream(stream);
            if (userVideo.current) {
                userVideo.current.srcObject = stream;
            }
        });

        socket.on("callAccepted", (signal) => {
            setCallAccepted(true);
            setLoading(false);
            connectionRef.current.signal(signal);
        });

        return () => {
            socket.off("callIncoming");
            socket.off("callAccepted");
        };
    }, [socket]);

    const callUser = (id) => {
        setLoading(true);
        const peer = new SimplePeer({
            initiator: true,
            trickle: false,
            stream: stream
        });

        peer.on("signal", (data) => {
            socket.emit("callUser", { recipient_id: id, signal: data });
        });

        peer.on("stream", (stream) => {
            if (partnerVideo.current) {
                partnerVideo.current.srcObject = stream;
            }
        });

        connectionRef.current = peer;
    };

    const answerCall = () => {
        setCallAccepted(true);
        const peer = new SimplePeer({
            initiator: false,
            trickle: false,
            stream: stream
        });

        peer.on("signal", (data) => {
            socket.emit("answerCall", { signal: data, to: caller });
        });

        peer.on("stream", (stream) => {
            if (partnerVideo.current) {
                partnerVideo.current.srcObject = stream;
            }
        });

        peer.signal(callerSignal);
        connectionRef.current = peer;
    };

    const leaveCall = () => {
        setCallEnded(true);
        connectionRef.current.destroy();
        window.location.reload();
    };

    return (
        <div className="w-full h-full flex flex-col relative">
            <div className="flex flex-col w-full h-full z-[10] relative">
                {callAccepted && !callEnded && (
                    <video playsInline ref={partnerVideo} autoPlay className="w-full h-full object-cover"/>
                )}
            </div>
            <div className="flex flex-col absolute w-[250px] h-[150px] rounded-lg bottom-5 right-5 rela z-[10]">
                {stream && (
                    <video width={300} playsInline muted ref={userVideo} autoPlay className="w-[300px] h-full"/>
                )}
            </div>
            <div
                onClick={() => {
                    callUser(recipientId)
                }}
                className={`absolute bg-green-500 rounded-full w-[80px] h-[80px] bottom-5 self-center left-[40%] cursor-pointer z-[10] flex flex-row items-center justify-center`}>
                <MdOutlineWifiCalling className={'text-white text-[32px] cursor-pointer'}/>
            </div>

            <div onClick={() => {
                receivingCall && !callAccepted ? answerCall() : leaveCall()
            }}
                 className={`absolute ${receivingCall && !callAccepted ? ' bg-blue-500 ' : ' bg-red-500 '} rounded-full w-[80px] h-[80px] bottom-5 self-center left-[50%] cursor-pointer  z-[10]`}>
            </div>

            <div className="absolute top-5 left-5 w-full h-full bg-gray-800 -z-0">
                {loading && <div className={' bg-gray-800 text-gray-800'}>Calling...</div>}
            </div>
        </div>
    );
};

export default VideoChat;
