import './App.css';
import Sidebar from "./components/Sidebar";
import Layout from "antd/es/layout/layout";
import {Route, Routes} from "react-router-dom";

import Dashboard from "./components/Dashboard";
import Main from "./pages/Main/Main";
import {useEffect, useState} from "react";
import {message, notification} from "antd";
import {SmileOutlined} from "@ant-design/icons";



function App() {
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (title,description) => {
        api.open({
            message: 'Yangi buyurtma keldi № = '+ title,
            description:
               description,
            icon: (
                <SmileOutlined
                    style={{
                        color: '#108ee9',
                    }}
                />
            ),
        });
    };
    useEffect(() => {
        const ws = new WebSocket('wss://api.osonexpress.uz/websocket/orders');

        ws.onopen = () => {
            console.log('WebSocket соединение установлено');
        };

        ws.onmessage = (event) => {
            try {
                const message = JSON.parse(event.data);
                // Обработка сообщения
                openNotification(message.id, message.address);
            } catch (error) {
            }

        };

        ws.onclose = () => {
            console.log('WebSocket соединение закрыто');
        };

        return () => {
            ws.close();
        };
    }, []);

  return (
    <div className="App">
        {contextHolder}
      <Main/>
    </div>
  );
}

export default App;
