import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const categoryAPI = axios.create({
    baseURL,
});

// Получить все категории
export const DialogGetCategories = async (lang) => {
    const token = localStorage.getItem('token');
    try {
        const response = await categoryAPI.get(`${baseURL}api/categories?lang=${lang}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

// Получить категорию по ID
export const DialogShowCategory = async (id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await categoryAPI.get(`${baseURL}api/categories/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

// Создать новую категорию
export const DialogAddCategory = async (data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await categoryAPI.post(`${baseURL}api/categories`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

// Удалить категорию
export const DialogDeleteCategory = async (CategoryID) => {
    const token = localStorage.getItem('token');
    try {
        const response = await categoryAPI.delete(`${baseURL}api/categories/${CategoryID}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

// Обновить категорию
export const DialogUpdateCategory = async (CategoryID, data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await categoryAPI.put(`${baseURL}api/categories/${CategoryID}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};
