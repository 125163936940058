import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const achievementsAPI = axios.create({
    baseURL,
});

export const getAchievements = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await achievementsAPI.get(`${baseURL}api/achievements`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log(response.data);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const showAchievement = async (id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await achievementsAPI.get(`${baseURL}api/achievements/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const addAchievement = async (data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await achievementsAPI.post(`${baseURL}api/achievements`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const deleteAchievement = async (achievementID) => {
    const token = localStorage.getItem('token');
    try {
        const response = await achievementsAPI.delete(`${baseURL}api/achievements/${achievementID}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const updateAchievement = async (achievementID, data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await achievementsAPI.put(`${baseURL}api/achievements/${achievementID}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};
