// courierCard.js
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const courierCardAPI = axios.create({
    baseURL,
});
export const getCourierCard = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await courierCardAPI.get(`${baseURL}api/courier-cards`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const getCourierCardById = async (id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await courierCardAPI.get(`${baseURL}api/courier-cards/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const increaseCourierCardCount = async (id,count) => {
    const token = localStorage.getItem('token');
    try {
        const response = await courierCardAPI.put(`${baseURL}api/courier-cards/${id}/increase-count`, {count:count}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const decreaseCourierCardCount = async (id,count) => {
    const token = localStorage.getItem('token');
    try {
        const response = await courierCardAPI.put(`${baseURL}api/courier-cards/${id}/decrease-count`, {count:count}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};
