import React, {useEffect, useState} from "react";
import styles from "./style.module.css";
import {
    Button,
    Drawer,
    Form,
    Input, InputNumber,
    message,
    Modal,
    Popconfirm, Popover,
    Select,
    Table
} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {LiaUserShieldSolid} from "react-icons/lia";
import {Excel} from "antd-table-saveas-excel";
import {SiMicrosoftexcel} from "react-icons/si";


import {Option} from "antd/es/mentions";

import {useSelector} from "react-redux";
import {
    addWarehouseBottle, deleteWarehouseBottle,
    getWarehouseBottles,
    showWarehouseBottle,
    updateWarehouseBottle
} from "../../../api/service_warehouse_bottles";
import {getBottles} from "../../../api/service_bottles";
import {getWarehouses} from "../../../api/service_warehouse";
import {
    addWarehouse,
    decreaseWarehouseCount,
    deleteWarehouse,
    increaseWarehouseCount
} from "../../../api/ACC/WarehousesApi";
import {getProducts} from "../../../api/products_api";

export default function WarehouseProduct() {

    const [edit, setEdit] = useState(false)

    const [isLoading, setLoading] = useState(false)

    const [warehouseBottle, setWarehouseBottle] = useState([])
    const [warehouseBottleOld, setWarehouseBottleOld] = useState([])
    const [selectedWarehouseBottles, setSelectedWarehouseBottles] = useState([]);
    const [products, setProducts] = useState([]);

    const [warehouse, setWarehouse] = useState([])

    const [bottles, setBottles] = useState([])

    const [isCashback, setCashback] = useState(true);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [open, setOpen] = useState(false);


    const [form] = Form.useForm();

    const theme = useSelector((state) => state.theme.theme);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit()
        setEdit(false)
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setEdit(false)
        setIsModalOpen(false);
    };
    const onClose = () => {
        setOpen(false);
    };
    const actionColumn = {
        title: 'sonini o`zgartirish',
        key: 'action',
        render: (text, record) => {
            let count = 1; // Значение для увеличения по умолчанию

            // Функция для увеличения количества
            const handleIncrease = async () => {
                try {
                    await increaseWarehouseCount(record.id, count);
                    await fetchWarehouseProduct()
                    message.success('maxsulot muvoffaqiyatli ko`paydi')
                    // Обновляем данные, если нужно
                } catch (error) {
                    message.error('Internetni tekshiring yoki xatolik yuz berdi')
                }
            };

            // Функция для уменьшения количества
            const handleDecrease = async () => {
                try {
                    if (record.count < count) {
                        message.warning('kamayish maxsulotlar sonidan ko`p bo`la olmaydi')
                    } else {
                        await decreaseWarehouseCount(record.id, count);
                        await fetchWarehouseProduct()
                        message.success('maxsulot muvoffaqiyatli kamaydi')
                    }
                    // Обновляем данные, если нужно
                } catch (error) {
                    message.error('Internetni tekshiring yoki xatolik yuz berdi')
                }
            };

            return (
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <InputNumber min={1} defaultValue={1} onChange={(value) => (count = value)}
                                 style={{marginRight: '8px'}}/>
                    <Button className={'bg-green-600 text-white'} onClick={handleIncrease} icon={<PlusOutlined/>}
                            style={{marginRight: '8px'}}/>
                    <Button className={'bg-red-600 text-white'} onClick={handleDecrease} icon={<MinusOutlined/>}/>
                </div>
            );
        }
    };

    useEffect(() => {
        fetchWarehouseProduct()
        fetchProduct()
    }, [])

    const fetchProduct = async () => {
        setLoading(true);
        try {
            const data = await getProducts('uz');
            setProducts(data);
        } catch (error) {
            console.error('MAxsulot qo`shishda xatolik yuz berdi :', error);
        }
        setLoading(false);
    };
    const fetchWarehouseProduct = async () => {
        setLoading(true);
        try {
            const data = await getWarehouses();
            setWarehouseBottle(data);
        } catch (error) {
            console.error('Maxsulot qo`shishda xatolik :', error);
        }
        setLoading(false);
    };

    const onFinish = async (values) => {
        console.log("submit")
        try {
            if (edit) {

                await fetchWarehouseProduct()
            } else {
                 await addWarehouse(values)
                await fetchWarehouseProduct()
            }
        } catch (error) {
            console.log('Omborga maxsulot qo`shishda xatolik yuz berdi ', error)
        }
    }

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Maxsulot nomi',
            dataIndex: 'product',
            key: 'product',
            render: product => product?.name
        },
        {
            title: 'Maxsulot soni',
            dataIndex: 'count',
            key: 'count'
        },
        actionColumn,
        {
            title: 'Umumiy summada narxi',
            dataIndex: 'count',
            key: 'count',
            render: (text, record) => {
                const totalPrice = parseInt(record.count) * record.product.price;
                const coverURL = process.env.REACT_APP_URL + record.product.cover[0];
                const content = (
                    <div className={'flex flex-col'}>
                        <p>soni: {record.count}</p>
                        <p>narxi: {record.product.price} so`m</p>
                        <p>surati:</p>
                        <img className={'self-center rounded-lg'} src={coverURL} alt="Cover"
                             style={{maxWidth: '150px', maxHeight: '150px'}}/>
                    </div>
                );
                return (
                    <Popover className={'cursor-pointer'} content={content} title="Детали">
                        <div className={'bg-green-600 rounded-lg text-white p-1 w-fit'}>
                            {totalPrice} so`m
                        </div>
                    </Popover>
                );
            }
        },

        // {
        //     title: 'Warehouse',
        //     dataIndex: 'warehouse',
        //     render: warehouse=> warehouse?.name
        // },
        {
            title: 'Amallar',
            render: (record) => {
                return <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                    <div onClick={async () => {
                        // const response = await showWarehouseBottle(record.id);
                        // setSelectedWarehouseBottles(response)
                        setOpen(true)
                    }} className={'action_buttons bg-indigo-500 text-white'}>
                        <EyeOutlined/>
                    </div>
                    <div onClick={() => {
                        setEdit(true)
                        setSelectedWarehouseBottles(record)
                    }} className={'action_buttons bg-amber-500 text-white'}>
                        <EditOutlined/>
                    </div>

                    <Popconfirm
                        title="Delete the bottle type "
                        description="Are you sure to delete this type of bottle ?"
                        onConfirm={async () => {

                            await deleteWarehouse(record.id)
                            await fetchWarehouseProduct()
                        }
                        }
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={deleteButton}
                    >
                        <div className={'action_buttons bg-red-600 text-white'}>
                            <DeleteOutlined/>
                        </div>
                    </Popconfirm>


                </div>
            }
        }
    ];

    const columns2 = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'MAxsulot nomi',
            dataIndex: 'bottle',
            key: 'bottle',
            render: bottle => bottle?.name
        },
        {
            title: 'Maxsulot soni',
            dataIndex: 'count',
            key: 'count'
        },
        // {
        //     title: 'Warehouse',
        //     dataIndex: 'warehouse',
        //     render: warehouse=> warehouse?.name
        // },

    ];

    const handleClick = () => {
        const excel = new Excel();
        excel
            .addSheet("test")
            .addColumns(columns2)
            .addDataSource(warehouseBottle, {
                str2Percent: true
            })
            .saveAs("Excel.xlsx");
    };

    useEffect(() => {

    }, [warehouseBottle])

    const onSearch = (values) => {

        if (values.target.value.length === 0) {
            setWarehouseBottle(warehouseBottleOld)
        } else {
            setWarehouseBottle(filterWarehouseBottles(values.target.value.toLowerCase()))
        }


    }

    // axios.put(baseUrl, data)
    function filterWarehouseBottles(value) {
        return warehouseBottle.filter(warehouseBottle => {

            return warehouseBottle.bottle?.name.toLowerCase().includes(value) || warehouseBottle.warehouse?.name.toLowerCase().includes(value) || warehouseBottle?.count.toLowerCase().includes(value);

        });
    }

    const deleteButton = {
        style: {
            backgroundColor: '#c00900', // Set the default background color
        },
        // Add other props as needed
    };

    const confirmButtonProps = {
        style: {
            backgroundColor: '#0c75ff', // Set the default background color
        },
        // Add other props as needed
    };

    const fetchBottles = async () => {
        setLoading(true);
        try {
            const data = await getBottles();
            setBottles(data);
        } catch (error) {
            console.error('Error fetching bottles :', error);
        }
        setLoading(false);
    };

    const fetchWarehouse = async () => {
        setLoading(true);
        try {
            const data = await getWarehouses();
            setWarehouse(data);
        } catch (error) {
            console.error('Error fetching warehouse bottles :', error);
        }
        setLoading(false);
    };

    console.log(bottles)

    return <div className={'w-full'}>
        <div className={styles.container}>
            <div className={styles.header_bottles}>

                <div className={styles.title}>
                    Omborxona mollari
                    <LiaUserShieldSolid className={'text-[28px]'}/>
                </div>

                <div className={styles.tools}>
                    <div className={'flex flex-row items-center gap-x-2'}>
                        <Button
                            onClick={showModal}
                            type="primary"
                            className={'bg-green-600'}
                        >
                            Omborxonaga maxsulot qo`shish +
                        </Button>

                        <Button type={"primary"} onClick={() => {
                            handleClick()
                        }}
                                className={'bg-blue-400 flex flex-row text-xl items-center'}><SiMicrosoftexcel/>
                        </Button>
                    </div>

                    <div className={'flex flex-row items-center'}>
                        <div
                            className={`font-sans font-semibold ${theme === 'dark' ? 'text-white' : 'text-black/60'} text-black/60 mr-4`}>Izlash
                        </div>
                        <Input
                            placeholder="Izlash"
                            allowClear
                            onChange={onSearch}
                            style={{
                                width: 200,
                            }}
                        />
                    </div>

                </div>

            </div>

            <Modal title="Omborxona" open={isModalOpen || edit} okButtonProps={confirmButtonProps} onOk={handleOk}
                   onCancel={handleCancel}>
                <Form
                    form={form}
                    name="Omborxonaga qo`shish"
                    initialValues={{remember: true, cashbackPercent: 0}}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Maxsulot nomi"
                        name="product_id"
                        rules={[
                            {
                                required: true,
                                message: 'Please select the bottle name!',
                            },
                        ]}
                    >
                        <Select>
                            {products?.map((type) => (
                                <Option value={type.id}>
                                    {type.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="maxsulot soni"
                        name="count"
                        rules={[
                            {
                                type: "number",
                                required: true,
                                message: 'Inltimos maxsulot sonini kiriting!',
                            },
                        ]}
                    >
                        <InputNumber/>
                    </Form.Item>



                </Form>


            </Modal>
            <div className={`w-full ${theme === 'dark' ? 'custom_dark' : `custom_light`}`}>
                <Table className={'shadow-2xl w-full  rounded-xl'} dataSource={warehouseBottle} columns={columns}/>
            </div>

            <Drawer title="Bottle" onClose={onClose} open={open}>

                <div className={'flex w-full flex-col items-center text-start gap-y-2'}>

                    <div className={'flex flex-row items-center gap-x-2'}>
                        <p className={'text-xl'}>Name : </p>

                        <p className={'text-xl font-semibold'}>{selectedWarehouseBottles?.bottles_id}</p>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2 '}>
                        <p className={'text-xl'}>Bottle type : </p>

                        <p className={'text-xl font-semibold'}>{selectedWarehouseBottles?.count}</p>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2 '}>
                        <p className={'text-xl'}>Cashback Price : </p>

                        <p className={'text-xl font-semibold'}>{selectedWarehouseBottles?.warehouses_id}</p>
                    </div>
                </div>


            </Drawer>
        </div>
    </div>
}
